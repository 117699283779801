import React from "react"
import { _getLocalizedText } from '../core/utils';

const ChapoVelo = ({ titraille, title, texte, metas }) => {
	// console.log(metas)
	return (
		<section className="chapo">
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-2 hidden-sm"></div>
					<div className="col-md-7 col-xs-12">
						<div className="inner">
							{title && <h1>{title}</h1>}
							{titraille && <div className="h1 fL">{titraille}</div>}
							<div
								className="texte"
								dangerouslySetInnerHTML={{ __html: texte }} ></div>
						</div>
					</div>
					<div className="col-sm-3 hidden-sm">
						{metas &&
							<aside className="fS">
								<ul className="metas">
									<li>
										<div className="meta-key">{_getLocalizedText("delivered")}</div>
										<div className="meta-val">{`${_getLocalizedText("the")} ${metas.livraison}`}</div>
									</li>
									<li>
										<div className="meta-key">Réalisé par</div>
										<div className="meta-val">
											<ul>
												{metas.equipe.map( ({collaborateurs}, i) => (
													<li key={i}>
														{collaborateurs.document 
														&& collaborateurs.document.data
														&& collaborateurs.document.data.prenom
														&& collaborateurs.document.data.prenom.text &&
														
															<span>
																{`${collaborateurs.document.data.prenom.text}, ${collaborateurs.document.data.role}`}
															</span>
															
														}
													</li>
												))}
											</ul>
										</div>
									</li>
								</ul>
							</aside>
						}
					</div>
				</div>
			</div>
		</section>
	)
}

export default ChapoVelo
