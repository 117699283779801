import React from "react"
import jsonp from "jsonp"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"

class Mailchimp extends React.Component {
	state = {
		status: "",
		ready: false,
	}
	componentDidMount() {
		this.setState({
			ready: true,
		})
	}

	handleSubmit(evt) {
		evt.preventDefault()
		const { fields, action } = this.props
		const values = fields
			.map((field) => {
				return `${field.name}=${encodeURIComponent(this.state[field.name])}`
			})
			.join("&")
		const path = `${action}&${values}`
		const url = path.replace("/post?", "/post-json?")
		const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/
		const email = this.state["EMAIL"]
		!regex.test(email) ? this.setState({ status: "empty" }) : this.sendData(url)
	}

	sendData(url) {
		this.setState({ status: "sending" })
		jsonp(url, { param: "c" }, (err, data) => {
			if (data.msg.includes("already subscribed")) {
				this.setState({ status: "duplicate" })
			} else if (err) {
				this.setState({ status: "error" })
			} else if (data.result !== "success") {
				this.setState({ status: "error" })
			} else {
				this.setState({ status: "success" })
			}
		})
	}

	getButtonMsg() {
		const messages = {
			...Mailchimp.defaultProps.messages,
			...this.props.messages,
		}

		const { status } = this.state
		// console.log(status)
		switch (status) {
			case "sending":
				return messages.sending
			case "success":
				return messages.success
			case "duplicate":
				return messages.duplicate
			case "empty":
				return messages.empty
			case "error":
				return messages.error
			default:
				return messages.button
		}
	}

	render() {
		const { fields, styles, className, formClassName, texte, hasToolTip } =
			this.props
		const messages = {
			...Mailchimp.defaultProps.messages,
			...this.props.messages,
		}
		// console.log(hasToolTip)
		const { status, ready } = this.state
		return (
			<form
				onSubmit={this.handleSubmit.bind(this)}
				className={`${className} ${formClassName}`}
			>
				<div className="inner">
					<div className="form-row">
						{fields.map((input) => (
							<input
								className="fRegIt"
								{...input}
								key={input.name}
								onChange={({ target }) =>
									this.setState({ [input.name]: target.value })
								}
								defaultValue={this.state[input.name]}
							/>
						))}
					</div>
					<div className="form-row">
						<button
							disabled={status === "sending" || status === "success"}
							type="submit"
							className=" has-icon chevron-e"
							data-tip=""
						>
							{/* {hasToolTip && ready && status === "" && (
								<ReactTooltip
									place="top"
									type="warning"
									effect="solid"
									offset={{ top: -10 }}
									multiline={true}
									html={true}
									className="tooltip"
									aria-haspopup="true"
									// getContent={() => { return texte.html }}
									// delayHide={1000}
									backgroundColor="#EAE3D5"
									textColor="#454545"
									clickable={true}
								>
									{texte.html}
								</ReactTooltip>
							)} */}

							{/* <span>{messages.button}</span> */}
							<span>{this.getButtonMsg()}</span>
						</button>
					</div>
				</div>
				{/* <div className='msg-alert'>
          {status === "sending" && <p style={styles.sendingMsg}>{messages.sending}</p>}
          {status === "success" && <p style={styles.successMsg}>{messages.success}</p>}
          {status === "duplicate" && <p style={styles.duplicateMsg}>{messages.duplicate}</p>}
          {status === "empty" && <p style={styles.errorMsg}>{messages.empty}</p>}
          {status === "error" && <p style={styles.errorMsg}>{messages.error}</p>}
        </div> */}
			</form>
		)
	}
}

Mailchimp.defaultProps = {
	messages: {
		// initial: "Sending...",
		sending: "Sending...",
		success: "Thank you for subscribing!",
		error: "An unexpected internal error has occurred.",
		empty: "You must write an e-mail.",
		duplicate: "Too many subscribe attempts for this email address",
		button: "Subscribe!",
	},
	buttonClassName: "",
	styles: {
		sendingMsg: {
			color: "#0652DD",
		},
		successMsg: {
			color: "#009432",
		},
		duplicateMsg: {
			color: "#EE5A24",
		},
		errorMsg: {
			color: "#ED4C67",
		},
	},
}

Mailchimp.propTypes = {
	action: PropTypes.string,
	messages: PropTypes.object,
	fields: PropTypes.array,
	styles: PropTypes.object,
	className: PropTypes.string,
	buttonClassName: PropTypes.string,
}

export default Mailchimp
