exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-en-404-jsx": () => import("./../../../src/pages/en/404.jsx" /* webpackChunkName: "component---src-pages-en-404-jsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-page-blog-category-jsx": () => import("./../../../src/templates/page-blog-category.jsx" /* webpackChunkName: "component---src-templates-page-blog-category-jsx" */),
  "component---src-templates-page-blog-jsx": () => import("./../../../src/templates/page-blog.jsx" /* webpackChunkName: "component---src-templates-page-blog-jsx" */),
  "component---src-templates-page-blog-post-jsx": () => import("./../../../src/templates/page-blog-post.jsx" /* webpackChunkName: "component---src-templates-page-blog-post-jsx" */),
  "component---src-templates-page-default-jsx": () => import("./../../../src/templates/page-default.jsx" /* webpackChunkName: "component---src-templates-page-default-jsx" */),
  "component---src-templates-page-events-jsx": () => import("./../../../src/templates/page-events.jsx" /* webpackChunkName: "component---src-templates-page-events-jsx" */),
  "component---src-templates-page-home-jsx": () => import("./../../../src/templates/page-home.jsx" /* webpackChunkName: "component---src-templates-page-home-jsx" */),
  "component---src-templates-page-velo-jsx": () => import("./../../../src/templates/page-velo.jsx" /* webpackChunkName: "component---src-templates-page-velo-jsx" */),
  "component---src-templates-page-velos-category-jsx": () => import("./../../../src/templates/page-velos-category.jsx" /* webpackChunkName: "component---src-templates-page-velos-category-jsx" */),
  "component---src-templates-page-velos-jsx": () => import("./../../../src/templates/page-velos.jsx" /* webpackChunkName: "component---src-templates-page-velos-jsx" */)
}

