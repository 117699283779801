import React, { useState } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import VeloIndex from "../VeloIndex"

//direction false = colonne
//reverse true = text|img

const ImagesTexte = ({ input }) => {
	// const { direction, reverse } = input.primary
	// console.log(input)
	const isRow = input.primary.direction
	const isReverse = input.primary.reverse
	// console.log("isRow", isRow, "isReverse", isReverse , input.primary.texte.html)
	return (
		<section className="section section-images-texte">
			<div className="container-fluid">
				{!isRow && <ImagesTexteColumn input={input} reverse={isReverse} />}

				{isRow && <ImagesTexteRow input={input} reverse={isReverse} />}
			</div>
		</section>
	)
}

const ImagesTexteRow = ({ input, reverse }) => (
	<div className={`row is-images-row`}>
		<div className="col-xs-12">
			<div className={`row ${reverse ? "is-m-reverse" : ""}`}>
				{reverse && (
					<>
						<div className="hidden-xs col-md-2"></div>
						<div className="col-xs-12 col-md-4">
							<div
								className="texte"
								dangerouslySetInnerHTML={{ __html: input.primary.texte.html }}
							/>
						</div>
					</>
				)}
				{/* <pre>{JSON.stringify(input.items, false, 2)}</pre> */}
				{input.items.map(({ image, link }, i) => {
					if (!image || !image.gatsbyImageData) return null

					const hasCaption = input.primary.texte.text !== ""
					const isLast = i === input.items.length - 1

					return (
						<div
							key={i}
							className={`col-xs-12 col-md-6 ${
								image.gatsbyImageData?.width > image.gatsbyImageData?.height
									? "is-landscape"
									: "is-portrait"
							} `}
						>
							<Figure
								image={image}
								link={link}
								isLast={isLast && !hasCaption}
							/>
							{/* <pre>{JSON.stringify(image, false, 2)}</pre> */}
							{/* {link} */}
							{/* {`${i} - ${input.items.length} ${isLast} ${hasCaption}`} */}
						</div>
					)
				})}

				{!reverse && (
					<>
						{input.items.length > 1 && !(input.items.length % 2) && (
							<div className="hidden-xs col-md-2"></div>
						)}
						<div className="col-xs-12 col-md-4">
							<div
								className="texte"
								dangerouslySetInnerHTML={{ __html: input.primary.texte.html }}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	</div>
)

const ImagesTexteColumn = ({ input, reverse }) => (
	<div className={`row ${reverse ? "reverse" : "noooooo"}`}>
		<div className="col-xs-12 col-md-6">
			<div className="inner">
				{input.items.map(({ image, link }, i) => (
					<Figure key={i} image={image} link={link} />
				))}
			</div>
		</div>

		<div className="col-xs-12 col-md-4">
			<div
				className="texte"
				dangerouslySetInnerHTML={{ __html: input.primary.texte.html }}
			/>
		</div>
		<div className="hidden-xs col-md-2"></div>
	</div>
)

const Figure = ({ image, link, isLast }) => {
	const [loadingClass, setLoadingClass] = useState("loading")
	const _onLoad = () => {
		setLoadingClass("")
	}
	// console.log(image)

	return (
		<figure
			className={`image ${loadingClass} ${
				image.fluid?.aspectRatio > 1 ? "landscape" : "portrait"
			} ${isLast ? "is-last" : ""}`}
		>
			{/* <Img {...image} onLoad={_onLoad} /> */}
			<GatsbyImage
				image={image.gatsbyImageData}
				alt={`Vélo ${link.document?.data?.index}`}
				onLoad={_onLoad}
			/>
			{link && link.document && link.document.data && (
				<Link className="fS x xac" to={link.url}>
					<span>Voir le</span> <VeloIndex index={link.document.data.index} />{" "}
					<span className="icon-chevron-e"></span>
				</Link>
			)}
		</figure>
	)
}

export default ImagesTexte
