import React, { useEffect } from 'react'
// import ReactPlayer from 'react-player'
import VideoWrapper from '../ui/VideoWrapper'

const Video = ({ input, autoplay }) => {
	const [mounted, setMounted] = React.useState(false)
	const { reverse } = input

	useEffect(() => {
		setMounted(true)
	}, [])
	// console.log(input.embed)
	return (
		<section className="section section-video-texte">
			<div className="container-fluid">
				<div className={`row ${reverse ? 'reverse' : ''}`}>
					<div className="col-sm-6 col-xs-12">
						{mounted && (
							<VideoWrapper input={input.embed} autoplay={autoplay} />
						)}
					</div>
					<div className="col-sm-4 col-xs-12 col-texte">
						<div
							className="texte "
							dangerouslySetInnerHTML={{
								__html: input.texte.html,
							}}
						></div>
					</div>
					<div className="col-sm-2 hidden-xs"></div>
				</div>
			</div>
		</section>
	)
}

export default Video
