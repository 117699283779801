import React, { useState } from 'react';

const Accordeon = ({input}) => {
  // const [index, setIndex] = useState()

  // const _onClick = (_index) => {
  //   const newIndex = _index == index ? null : _index
  //   setIndex(newIndex)
  // }

  return (
    <section className={"section section-accordeon"}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 hidden-xs"></div>
          <div className="col-md-8 col-xs-12">
          {input.map((item, i) => (
            <AccordeonItem key={i} input={item} />
          ))}
				</div>
        </div>
			</div>
    </section>
  );
};

export default Accordeon;


const AccordeonItem = ({input}) => {
  const [active, setActive] = useState(input.active == true)

  const _onClick = (e) => {
    setActive(!active)
  }

  return(
    <div className={`accordeon-item ${(active ? 'is-active' : '')}`} >
      <div className="accordeon-item--title curp" onClick={_onClick}>
        <div className="x xjb">
          <div className="title">{input.accordeon_title}</div>
          {active
          ? <span className="icon-chevron-n"></span>
          : <span className="icon-chevron-s"></span>
          }
        </div>
      </div>
      <div className="accordeon-item--texte">
        <div
          className="texte "
          dangerouslySetInnerHTML={{
            __html: input.accordeon_texte.html,
          }} ></div>
      </div>
    </div>
  )
}