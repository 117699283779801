import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
// import { mergePrismicPreviewData } from "gatsby-source-prismic"
import SEO from '../components/seo'
import Hero from '../components/sections/Hero'
import Chapo from '../components/Chapo'
import Sections from '../components/sections'
import Rebonds from '../components/Rebonds'
import PageHeader from '../components/PageHeader'

export const pageQuery = graphql`
	query PageBySlug($uid: String!, $locale: String!) {
		prismicPage(uid: { eq: $uid }, lang: { eq: $locale }) {
			_previewable
			data {
				...page
			}
		}
	}
`

const PageDefault = ({ data }) => {
	const { title, texte, hero, template, body, rebond_image, rebond_texte } =
		data.prismicPage.data
	// console.log(data)

	const hasHero = hero.url !== null
	const templateStyle = hasHero ? 'has-hero' : 'theme-or'
	return (
		<>
			<SEO
				pageTitle={title.text}
				pageDescription={texte.text}
				page={true}
				template={`template-default template-${template} ${templateStyle}`}
			/>

			{hero && hero.url && <Hero input={hero} />}

			<div className="page-content">
				{hasHero && (
					<>
						<PageHeader h1={title.text} />
						<Chapo title={''} texte={texte.html} />
					</>
				)}
				{!hasHero && <Chapo title={title.text} texte={texte.html} />}

				{body && <Sections body={body} context="default" />}
			</div>

			{rebond_texte && (
				<Rebonds
					input={{
						image: rebond_image,
						texte: rebond_texte,
					}}
				/>
			)}
		</>
	)
}

export default PageDefault
