import React, { useState, useEffect, useContext } from 'react'
// import { Link } from "gatsby"
import PubSub from 'pubsub-js'
import LangSwitcher from './ui/LangSwitcher'
import MenuItem from './MenuItem'
import NewsletterCard from './NewsletterCard'
import { WrapperContext } from './Layout'
import { _getLocalizedText } from '../core/utils'

const MenuMobile = ({ primaire, secondaire, tertiaire, quaternaire }) => {
	const [active, setActive] = useState(false)
	const [contactActive, setContactActive] = useState(false)

	const _WrapperContext = useContext(WrapperContext)
	const { locale, settings } = _WrapperContext
	const { newsletter_message, newsletter_image } = settings.data
	// alert(locale)
	useEffect(() => {
		const tokenRouteUpdate = PubSub.subscribe('ROUTE_UPDATE', (e, d) => {
			// console.log(e)
			setActive(false)
		})
		const tokenBurger = PubSub.subscribe('BURGER', (e, d) => {
			setActive(d)
		})
		return () => {
			PubSub.unsubscribe(tokenRouteUpdate)
			PubSub.unsubscribe(tokenBurger)
		}
	}, [])

	useEffect(() => {
		// console.log("MenuMobile: contactActive",contactActive)
		// PubSub.publish("CONTACT", contactActive)
	}, [contactActive])

	const _onContactClick = () => {
		// setContactActive(!contactActive)
		// setContactActive(true)
		PubSub.publish('CONTACT', true)
	}

	const _className = active ? 'is-open' : ''
	return (
		<div className={`menu-mobile ${_className}`}>
			<nav id="primary">
				<ul className="fL">
					{primaire.map(({ menu_item, title }, i) => (
						<li key={i}>
							<MenuItem node={menu_item} title={title} />
						</li>
					))}
				</ul>
			</nav>

			<nav id="secondary">
				<ul className="fL">
					{secondaire.map(({ menu_item, link_external, title }, i) => (
						<li key={i}>
							<MenuItem
								node={menu_item}
								title={title}
								link_external={link_external}
							/>
						</li>
					))}
				</ul>
			</nav>

			<nav id="contact">
				<ul className="fL">
					<li>
						<button onClick={_onContactClick}>
							{_getLocalizedText('contact')}
						</button>
					</li>
				</ul>
			</nav>

			<nav id="nav-switch-lang">
				<LangSwitcher locale={locale} />
			</nav>

			<NewsletterCard
				newsletter_image={newsletter_image}
				newsletter_message={newsletter_message}
			/>

			<nav id="tertiaire">
				<div className="menu-t-q fS">
					<div className="row">
						<div className="col-xs-6">
							<div className="title">{_getLocalizedText('legal')}</div>
							<ul className="">
								{tertiaire.map(({ menu_item, title }, i) => (
									<li key={i} className="">
										{/* <Link to={`/${menu_item.uid}`}>{title}</Link> */}
										<MenuItem node={menu_item} title={title} />
									</li>
								))}
							</ul>
						</div>
						<div className="col-xs-6">
							<div className="title">{_getLocalizedText('network')}</div>
							<ul className="fS">
								{quaternaire.map(({ menu_item, title }, i) => (
									<li key={i}>
										<a
											rel="noopener noreferrer"
											target="_blank"
											href={menu_item.url}
										>
											{title}
										</a>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</nav>
			{/*
      <nav id="quaternaire">

      </nav> */}
		</div>
	)
}

export default MenuMobile
