import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Chapo from "../components/Chapo"
import Article from "../components/Article"
import Rebonds from "../components/Rebonds"
import { _getLocalizedText, _localizedSlug } from "../core/utils"

export const pageQuery = graphql`
	query PageBlogCategory($uid: String!, $locale: String!) {
		category: prismicCategorieBlog(uid: {eq: $uid}, lang: { eq: $locale }) {
			data {
				title {
					text
				}
				texte {
					html
					text
				}
			}
		}
		articles: allPrismicArticle(filter: {
			data: {categories: {elemMatch: {category: {uid: {eq: $uid}}}}},
			lang: {eq: $locale}
			}) {
			edges {
				node {
					...article
				}
			}
		}
		page: prismicPage(data: {template: {eq: "blog"}}, lang: { eq: $locale}) {
			data {
				...page
			}
		}
	}
`


const PageBlogCategory = ({pageContext, data}) => {
	// console.log(pageContext)
	// console.log(props)
	const {
		locale
	} = pageContext

	const { category, articles, page } = data
	const { rebond_image, rebond_texte } = page.data
	// console.log(rebond_texte)
	return (
		<>
			<SEO
				pageTitle={category.data.title.text}
				pageDescription={category.data.texte.text || ""}
				page={true}
				template="template-blog-category theme-or"
			/>

			<div className="page-content">
				<Chapo 
				title={`Le blog : ${category.data.title.text.toLowerCase()}`} 
				texte={category.data.texte.html || ""} />
				{/* <hr/> */}
				{articles.edges.map(({ node }, i) => (
					<Article key={i} input={node} single={false} />
				))}

				<div className="blog-footer">
					<div className="container-fluid">
						<div className="row">
							<div className="hidden-sm col-md-2"></div>
							<div className="col-md-8 col-xs-12">
								<div className="b-t">
									<Link 
									to={_localizedSlug({type:"page", uid:"blog", lang:locale})}
									className="has-icon chevron-w">{_getLocalizedText("backBlog")}</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			
			</div>
			{rebond_texte && <Rebonds input={{
				image: rebond_image, 
				texte: rebond_texte
			}} />}
		</>
	)
}

export default PageBlogCategory

