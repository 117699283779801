import React, { useState } from 'react';
import ReactPlayer from 'react-player'

const VideoWrapper = ({input, autoplay}) => {
  const [mute, setMute] = useState(true)
  const [playing, setPlaying] = useState(autoplay)
  // const []
  const _onMute = () => setMute(!mute)

  const _onPlayPause = () => setPlaying(!playing)

  const _getEmbedUrl = () => {
    const isYoutube = input.embed_url.indexOf("youtube") > -1
    const isVimeo = input.embed_url.indexOf("vimeo") > -1
    if(isYoutube){
      return input.embed_url
      // const id = _getYoutubeId()
      // return `https://www.youtube-nocookie.com/embed/${id}`
    }
    if(isVimeo){
      return input.embed_url+"?dnt=1"
    }
  }

  const _getYoutubeId = () => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = input.embed_url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  const config = {
    youtube: {
      playerVars: {
        showinfo: 0,
        iv_load_policy: 3,
        cc_load_policy: 0,
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        origin: 'https://victoire.netlify.com/'
      },
      embedOptions:{
        host: 'https://www.youtube-nocookie.com'
      }
    },
    vimeo: {
      title: 'false'
    }
  }

  const embed_url = _getEmbedUrl()
// console.log(input.embed_url, embed_url)
  return (
    <div className="video-container">
      <ReactPlayer
        className='r-player'
        url={embed_url}
        // url="https://vimeo.com/78429727"
        playing={playing}
        loop={true}
        muted={mute}
        playsinline
        config={config}
        width="100%"
        style={{
          paddingTop:`calc(${input.height} / ${input.width} * 100%)`
        }}
      />
      {/* <div className="play-pause psa w100 h100 curp" onClick={_onPlayPause}></div> */}
      <div className="mute fM" onClick={_onMute}>
        {/* {mute ? 'muted' : 'unmuted'} */}
        <span className={`${mute ? 'icon-mute-on' : 'icon-mute-off'}`}></span>
      </div>
    </div>
  );
};

export default VideoWrapper;