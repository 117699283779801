import React from "react"

const Chapo = ({ titraille, title, texte }) => {
	// console.log(metas)
	return (
		<section className="chapo">
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-2 hidden-sm"></div>
					<div className="col-md-8 col-xs-12">
						<div className="inner">
							{title && <h1>{title}</h1>}
							{titraille && <div className="h1 fXL">{titraille}</div>}
							<div
								className="texte"
								dangerouslySetInnerHTML={{ __html: texte }} ></div>
						</div>
					</div>
					<div className="col-sm-2 hidden-sm"></div>
				</div>
			</div>
		</section>
	)
}

export default Chapo
