import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { _localizedSlug } from "../core/utils"
import VeloIndex from "./VeloIndex"
const placeholder = require("../images/placeholder.svg")

const VeloCard = ({ input, context }) => {
	const {
		data: { index, title, texte, livraison, image_principale },
	} = input
	// console.log(image_principale)
	const hasImage = image_principale.gatsbyImageData != null
	return (
		<article className={`card velo-card ${!hasImage ? "disabled" : ""}`}>
			<Link to={_localizedSlug(input)}>
				<div className="card-image">
					<div className="inner">
						{hasImage && (
							<>
								{/* <Img {...image_principale} Tag="figure" /> */}
								<GatsbyImage
									image={image_principale.gatsbyImageData}
									alt=""
									as="figure"
								/>
								<div className="overlay"></div>
							</>
						)}
						{/* <pre>{JSON.stringify(image_principale)}</pre> */}
						{!hasImage && (
							<div className="card-placeholder h100 oh">
								<img src={placeholder} alt="" />
								<div className="inner">
									<div className="texte fL sans">{texte.text}</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="card-header fS">
					<div className="x xw">
						<VeloIndex index={index} />
						<div className="card-header--title">
							{context === "h2" && <h2 className="fS">{title.text}</h2>}
							{context === "h3" && <h3 className="fS">{title.text}</h3>}
							{context === "h4" && <h4 className="fS">{title.text}</h4>}
							{livraison && <div className="date">, {livraison}</div>}
						</div>
					</div>
					{/* {input.data.categories.map( ({category}, i) => (
						<div key={i}>{category.uid}, </div>
					))} */}
				</div>
			</Link>
		</article>
	)
}

export default VeloCard
