import React, { useContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PubSub from 'pubsub-js'

import { WrapperContext, FiltersContext } from './Layout'
import MenuItem from './MenuItem'
import MenuModal from './MenuModal'
import MenuMobile from './MenuMobile'
import MenuAncre from './MenuAncre'
import MenuFiltres from './MenuFiltres'
import MenuBlog from './MenuBlog'
import MenuVelo from './MenuVelo'
import Annonce from './Annonce'
import Burger from './ui/Burger'
import Contact from './Contact'
import { _getLocalizedText } from '../core/utils'

// const logo = require("../images/victoire-logo.svg")
import Logo from '../images/victoire-logo.inline.svg'

const query = graphql`
	query Menu {
		prismicMenusFr: prismicMenus(lang: { eq: "fr-fr" }) {
			...menus
		}
		prismicMenusEn: prismicMenus(lang: { eq: "en-gb" }) {
			...menus
		}
	}
`

let _prevScrollTop = 0
const Header = () => {
	const _WrapperContext = useContext(WrapperContext)
	const {
		locale,
		settings,
		// template,
		pageInfos,
		annonce,
		HAS_MENU_ANCRE,
		HAS_HEADER_TRANSPARENT,
		THRESHOLD_N2,
		THRESHOLD_TRANSAPRENT,
		HAS_VELOS_FILTERS,
		HAS_MENU_VELO,
		HAS_MENU_CATEGORIES,
	} = _WrapperContext

	const _FiltersContext = useContext(FiltersContext)
	const { filters } = _FiltersContext
	// console.log(filters)

	const { prismicMenusFr, prismicMenusEn } = useStaticQuery(query)
	const prismicMenus = locale === 'fr-fr' ? prismicMenusFr : prismicMenusEn
	const { primaire, secondaire, tertiaire, quaternaire } = prismicMenus.data
	// console.log("annonce",annonce)
	// console.log("HAS_HEADER_TRANSPARENT",HAS_HEADER_TRANSPARENT, THRESHOLD_N2)
	const [headerTransparent, setHeaderTransparent] = useState(
		HAS_HEADER_TRANSPARENT
	)
	const [headerN2, setHeaderN2] = useState()
	const [direction, setDirection] = useState()
	const [menuActive, setMenuActive] = useState(false)
	const [contactActive, setContactActive] = useState(false)
	// const [menuAncre, _setMenuAncre] = useState()
	const [annonceIn, setAnnonceIn] = useState(annonce !== undefined)
	// const [annonceIn, setAnnonceIn] = useState(false)

	// let _prevScrollTop = 0
	let _headerHeight = 0
	let _annonceHeight = 0
	// console.log("HAS_HEADER_TRANSPARENT", HAS_HEADER_TRANSPARENT)
	// console.log("headerTransparent", headerTransparent)
	useEffect(() => {
		const token = PubSub.subscribe('ANNONCE_OUT', () => {
			setAnnonceIn(false)
		})
		if (annonceIn) {
			const timer = setInterval(() => {
				if (document.querySelector('.annonce')) {
					clearInterval(timer)
					_annonceHeight = document
						.querySelector('.annonce')
						.getBoundingClientRect().height
				}
			}, 100)
		}
		return () => {
			PubSub.unsubscribe(token)
			// if(annonceIn && timer)clearInterval(timer)
		}
	}, [annonceIn])

	useEffect(() => {
		_headerHeight = document
			.querySelector('.top-nav-primary')
			.getBoundingClientRect().height

		_prevScrollTop = window.pageYOffset

		document.addEventListener('scroll', _onScroll)
		_onScroll()

		const tokenRouteUpdate = PubSub.subscribe('ROUTE_UPDATE', (e, d) => {
			setMenuActive(false)
			setContactActive(false)
		})

		const tokenAnnonce = PubSub.subscribe('ANNONCE_OUT', (e, d) => {
			// setMenuActive(false)
			setAnnonceIn(false)
		})

		const tokenContact = PubSub.subscribe('CONTACT', (e, d) => {
			// console.log(d)
			setContactActive(d)
		})

		const tokenBurger = PubSub.subscribe('BURGER', (e, d) => {
			setMenuActive(d)
		})

		return () => {
			document.removeEventListener('scroll', _onScroll)
			PubSub.unsubscribe(tokenRouteUpdate)
			PubSub.unsubscribe(tokenAnnonce)
			PubSub.unsubscribe(tokenContact)
			PubSub.unsubscribe(tokenBurger)
		}
	}, [HAS_MENU_ANCRE, annonceIn, HAS_HEADER_TRANSPARENT, THRESHOLD_N2])

	useEffect(() => {
		// console.log(menuActive, contactActive)
		// PubSub.publish("TOGGLE_CONTACT")
		if (menuActive || contactActive) {
			document.body.classList.add('is-modal')
		} else {
			document.body.classList.remove('is-modal')
		}

		// if(!menuActive)setContactActive(false)
		// if(!contactActive)setMenuActive(false)
	}, [menuActive, contactActive])

	const _onScroll = () => {
		// console.log(annonceIn, window.pageYOffset, _headerHeight)
		if (annonceIn) {
			if (window.pageYOffset > _annonceHeight + _headerHeight) {
				// setAnnonceIn(false)
				PubSub.publish('ANNONCE_ANIME_OUT', true)
			} else {
				PubSub.publish('ANNONCE_ANIME_OUT', false)
			}
		}

		/*
		HEADER DIRECTION
		*/
		// console.log(window.pageYOffset, _prevScrollTop)
		window.pageYOffset > _prevScrollTop
			? setDirection('down')
			: setDirection('up')

		if (window.pageYOffset === 0) {
			setDirection('')
		}

		_prevScrollTop = window.pageYOffset
		let offset = annonceIn ? _annonceHeight : 0
		/*
		HEADER WHITE
		*/
		// console.log(HAS_HEADER_TRANSPARENT, _headerHeight)
		if (HAS_HEADER_TRANSPARENT) {
			const thresholdSection = document.querySelector(THRESHOLD_TRANSAPRENT)
			if (thresholdSection) {
				const { y } = thresholdSection.getBoundingClientRect()
				// console.log(annonceIn, y, offset)
				y < _headerHeight
					? setHeaderTransparent(false)
					: setHeaderTransparent(true)
			}
		} else {
			setHeaderTransparent(false)
		}
		// console.log(THRESHOLD_N2)

		/*
		HEADER N2
		*/
		const thresholdN2 = document.querySelector(THRESHOLD_N2)
		if (thresholdN2) {
			// console.log(thresholdN2)
			const { y } = thresholdN2.getBoundingClientRect()
			// console.log(THRESHOLD_N2, y)
			y < offset ? setHeaderN2(true) : setHeaderN2(false)
		} else {
			setHeaderN2(false)
		}

		// console.log(direction)
	}
	// console.log(direction)
	const _toggleMenu = () => {
		const newState = !menuActive
		setMenuActive(newState)

		if (newState) setContactActive(false)
	}

	const _toggleContact = () => {
		const newState = !contactActive
		setContactActive(newState)

		if (newState) setMenuActive(false)
	}

	const _onLogoClick = () => {
		// PubSub.publish("LOCO_CLICK")
		PubSub.publish('BURGER.CLOSE')
		setMenuActive(false)
		setContactActive(false)
	}

	const _getHomeUrl = () => (locale === 'fr-fr' ? '/' : '/en')
	// console.log(headerTransparent, active)
	// const isModal = active || contactActive
	const headerModalClass = menuActive || contactActive ? 'is-modal' : ''

	const headerTransparentClass =
		headerTransparent && !menuActive && !contactActive ? 'is-white' : ''

	const headerN2Class = headerN2 && !menuActive ? 'is-n2' : ''
	// const headerTransparentClass = headerTransparent
	// const annonceInRange =
	const annonceClass = annonceIn ? 'has-annonce' : ''
	const hasSecondaryNavClass =
		HAS_MENU_ANCRE || HAS_VELOS_FILTERS || HAS_MENU_VELO || HAS_MENU_CATEGORIES
			? 'has-secondary-nav'
			: ''
	const isFilteringClass = filters.length > 0 ? 'is-filtering' : ''

	// console.log(HAS_MENU_ANCRE)
	// console.log(annonceIn)
	return (
		<>
			<header
				className={`
		${headerModalClass}
		${headerTransparentClass}

		${direction}
		${hasSecondaryNavClass}
		${headerN2Class}
		${isFilteringClass}`}
			>
				{annonceIn && annonce && <Annonce input={annonce.data} />}

				<Contact active={contactActive} />

				<div className="header-main">
					<div className="site-name ">
						<Link to={_getHomeUrl()} onClick={_onLogoClick}>
							<Logo title={settings.data.site_name.text} />
						</Link>
					</div>

					<div className="top-nav-primary fS hidden-sm">
						<nav id="primary" className="">
							<ul className="row middle-xs">
								{primaire.map(({ menu_item, title }, i) => (
									<li key={i} className="col-sm-2">
										<MenuItem node={menu_item} title={title} />
									</li>
								))}
								<li className="col-sm-5"></li>
								<li className="col-sm-3 curp" onClick={() => _toggleMenu()}>
									<button className="">
										{menuActive
											? _getLocalizedText('close')
											: _getLocalizedText('menu')}
									</button>
								</li>
								{/* <li className="col-sm-1">

						</li> */}
							</ul>
						</nav>
					</div>

					<div className={`top-nav-secondary ${menuActive ? 'hidden' : ''}`}>
						{HAS_MENU_ANCRE && <MenuAncre />}
						{HAS_VELOS_FILTERS && (
							<MenuFiltres pageInfos={pageInfos} direction={direction} />
						)}
						{HAS_MENU_CATEGORIES && <MenuBlog pageInfos={pageInfos} />}
						{HAS_MENU_VELO && <MenuVelo pageInfos={pageInfos} />}
					</div>

					<button
						className={`btn btn-contact hidden-sm fS ${
							contactActive ? 'no-outline primary' : ''
						}`}
						onClick={_toggleContact}
					>
						{contactActive
							? _getLocalizedText('close')
							: _getLocalizedText('contact')}
					</button>

					<MenuModal
						secondaire={secondaire}
						tertiaire={tertiaire}
						quaternaire={quaternaire}
						active={menuActive}
					/>
				</div>
				<Burger />
				<MenuMobile
					primaire={primaire}
					secondaire={secondaire}
					tertiaire={tertiaire}
					quaternaire={quaternaire}
				/>
			</header>
		</>
	)
}

export default Header
