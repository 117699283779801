import React, { useState, useEffect, useContext } from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"
import PubSub from "pubsub-js"
import { WrapperContext } from "./Layout"
import { _getLocalizedText, _localizedSlug } from '../core/utils'

const MenuBlog = ({pageInfos}) => {
  const _WrapperContext = useContext(WrapperContext)
  const { locale } = _WrapperContext

  const [active, setActive] = useState()
  const { allPrismicCategorieBlog } = useStaticQuery(
    graphql`
    {
      allPrismicCategorieBlog {
        nodes {
          uid
          data {
            title {
              text
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if(active){
      const { height } = document.querySelector(".dropdown-content").getBoundingClientRect()
      // console.log(height)
      document.querySelector(".menu-n2").style.height = (height+40)+"px"
    }else{
      document.querySelector(".menu-n2").style.height = "auto"
    }
  }, [active])

  useEffect(() => {
    const tokenRouteUpdate = PubSub.subscribe("ROUTE_UPDATE", (e, d) => {
      setActive(false)
    })
    
    return () => PubSub.unsubscribe(tokenRouteUpdate)
  }, [])
  // console.log("active", active)
  return (
    <div className="menu-n2">
      <div className="row">
        {pageInfos && pageInfos.h1 &&
          <div className="col-md-2 col-xs-6">
            <div className="h1 fS">{pageInfos.h1}</div>
          </div>
        }
        <div className="col-md-8 col-xs-6">
          <div className="menu-dropdown fS">
            <div className={`dropdown ${active ? 'active' : ''}`}>
              {active && 
                <div className="dropdown-close" onClick={() => setActive(false)}></div>
              }
              <div 
              className="dropdown-toggle " 
              onClick={() => setActive(!active)}>
                <div className={`has-icon ${active ? 'chevron-n' : 'chevron-s'} `}>{_getLocalizedText("categories")}</div>
              </div>
              <div className="dropdown-content ">
                <ul>
                {allPrismicCategorieBlog.nodes.map((node, i) => (
                  <li key={i} >
                    <Link to={`/categorie-blog/${node.uid}`} >
                      {node.data.title.text}
                    </Link>
                  </li>
                ))}
                <li className="">
                
                <Link 
								to={_localizedSlug({type:"page", uid:"blog", lang:locale})}
								className="has-icon chevron-w ">{_getLocalizedText("allArticles")}</Link>
                </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default MenuBlog;