import React, { useState, useContext, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PubSub from 'pubsub-js'
import scrollIntoView from 'scroll-into-view'
import { WrapperContext, FiltersContext } from './Layout'
import { _getLocalizedText } from '../core/utils'
import MenuFiltresItem from './MenuFiltresItem'

const MenuFiltres = ({ pageInfos, direction }) => {
	const [active, setActive] = useState()

	const _WrapperContext = useContext(WrapperContext)
	const { locale, template, uid } = _WrapperContext

	const _FiltersContext = useContext(FiltersContext)
	const { filters, dispatch } = _FiltersContext
	// console.log(dispatch)

	const { allPrismicCategorieVeloFr, allPrismicCategorieVeloEn } =
		useStaticQuery(graphql`
			{
				allPrismicCategorieVeloFr: allPrismicCategorieVelo(
					filter: { lang: { eq: "fr-fr" } }
				) {
					...allCategorieVelo
				}
				allPrismicCategorieVeloEn: allPrismicCategorieVelo(
					filter: { lang: { eq: "en-gb" } }
				) {
					...allCategorieVelo
				}
			}
		`)
	const allPrismicCategorieVelo =
		locale === 'fr-fr' ? allPrismicCategorieVeloFr : allPrismicCategorieVeloEn

	// console.log(allPrismicCategorieVelo)
	useEffect(() => {
		// const tokenPreRouteUpdate = PubSub.subscribe("PRE_ROUTE_UPDATE", (e, d) => {
		//   console.log(d)
		//   _removeAll()
		// })
		const tokenRouteUpdate = PubSub.subscribe('ROUTE_UPDATE', (e, d) => {
			setActive(false)
		})
		// console.log(HAS_VELOS_FILTERS)
		// if(template === "velo-categorie"){
		//   console.log(uid)
		//   setTimeout(() => {
		//     dispatch({type:"ADD", payload: uid})
		//   }, 300)
		// }
		return () => {
			// PubSub.unsubscribe(tokenPreRouteUpdate)
			PubSub.unsubscribe(tokenRouteUpdate)
		}
	}, [])

	// useEffect(() => {
	//   // console.log(direction)
	//   if(direction === "up" && active){
	//     // setActive(false)
	//   }
	// }, [direction])

	useEffect(() => {
		// console.log(filters)
		if (window.innerWidth > 768) _onFilter()
	}, [filters])

	const _onFilter = () => {
		if (filters.length === 0) return
		// console.log("filters", filters)

		//dd content height
		const n2Height =
			document.querySelector('.menu-n2').getBoundingClientRect().height +
			document.querySelector('.dropdown-content').getBoundingClientRect().height

		scrollIntoView(document.querySelector('.grid'), {
			// debug: true
			align: {
				top: 0,
				topOffset: n2Height,
			},
		})
	}

	const _deleteFilter = filter => dispatch({ type: 'REMOVE', payload: filter })

	const _removeAll = () => {
		PubSub.publish('REMOVE_ALL')
		dispatch({ type: 'REMOVE_ALL' })
	}

	//mobile only
	const _valider = () => {
		// PubSub.publish("FILTRES_VALIDER")
		_onFilter()
		setActive(false)
	}

	const _getFilterNameByUid = _uid => {
		const match = allPrismicCategorieVelo.nodes.filter(
			node => node.uid === _uid
		)
		return match[0].data.title.text
	}

	// console.log(filters)
	return (
		<div className="menu-n2">
			<div className="row">
				{/* {pageInfos && pageInfos.h1 &&
          <div className="col-md-2 col-xs-6">
            <div className="h1 fS">{pageInfos.h1}</div>
          </div>
        } */}

				<div className="col-md-2 col-xs-6">
					<div className="h1 fS">{_getLocalizedText('theBikes')}</div>
				</div>

				<div className="col-md-8 col-xs-6">
					<div className="menu-dropdown menu-filtres fS">
						<div className={`dropdown ${active ? 'active' : ''}`}>
							{active && (
								<div
									className="dropdown-close"
									onClick={() => setActive(false)}
								></div>
							)}

							<div
								className="dropdown-toggle "
								onClick={() => setActive(!active)}
							>
								<div
									className={`has-icon ${active ? 'chevron-n' : 'chevron-s'} `}
								>
									{_getLocalizedText('filter')}
								</div>
							</div>
							<div className="dropdown-selection">
								<ul className="x xwn">
									{filters.map((el, i) => (
										<li
											key={i}
											className="chips"
											onClick={() => _deleteFilter(el)}
										>
											{/* <span>{el.replace(/-/g, " ")}</span> */}
											<span>{_getFilterNameByUid(el)}</span>
										</li>
									))}
								</ul>
							</div>

							<div className="dropdown-content ">
								<div className="sm-only tac fS ">
									<button
										id="btn-filtres-valider"
										className="btn--valider ttu"
										onClick={_valider}
									>
										{_getLocalizedText('validerSelection')}
									</button>
								</div>

								<ul className="x xw">
									{allPrismicCategorieVelo.nodes.map((node, i) => (
										<li key={i}>
											<MenuFiltresItem input={node} />
										</li>
									))}
									<li>
										<button className="btn no-outline" onClick={_removeAll}>
											{_getLocalizedText('eraseAll')} ×
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MenuFiltres
