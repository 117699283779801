import React from 'react';
import VeloIndex from './VeloIndex'

const MenuVelo = ({ pageInfos }) => (
  <div className="menu-n2">
    <div className="row">
      {pageInfos && pageInfos.index &&
        <div className="col-md-2 col-xs-6">
          <div className="h1 fS"><VeloIndex index={pageInfos.index}/></div>
        </div>
      }
      <div className="col-md-8 hidden-sm">
      <div className="h1 fS">{pageInfos.h1}</div>
      </div>
    </div>
  </div>
)


export default MenuVelo;