import React from "react"
import { _getTitleWithNumNormaliezd } from '../../core/utils'

const Headline = ({ input }) => {
	// console.log()
	return (
		<section 
		className="section section-headline fL">
			<div className="container-fluid">
				<div className="b-t">
					<div className="offset-2">
						{/* <h2>{input.title.text}</h2> */}
						<h2 
						data-title={input.title.text} 
						id={input.ancre} 
						dangerouslySetInnerHTML={{ 
								__html: _getTitleWithNumNormaliezd(input.title.text) }} 
							></h2>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Headline
