import React, { useEffect, useMemo } from 'react'
import { graphql } from 'gatsby'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'
//import PubSub from 'pubsub-js';
//import cookie from 'react-cookies'
import SEO from '../components/seo'
import HomeHero from '../components/HomeHero'
import HomeHeroBtn from '../components/HomeHeroBtn'
import Sections from '../components/sections'
import Rebonds from '../components/Rebonds'

export const pageQuery = graphql`
	query Home($locale: String!) {
		prismicHome(lang: { eq: $locale }) {
			_previewable
			data {
				title {
					text
				}
				seo_title
				seo_description {
					text
				}
				seo_image {
					url
				}
				velos {
					image {
						# ...sharp
						alt
						url
						gatsbyImageData(width: 2000)
					}
					image_mobile {
						# ...sharp
						alt
						url
						gatsbyImageData(width: 2000)
					}
					velo {
						document {
							... on PrismicVelo {
								uid
								lang
								type
								data {
									...veloCard
								}
							}
						}
					}
				}
				rebond_image {
					# ...sharp
					alt
					url
					gatsbyImageData(width: 2000)
				}
				rebond_texte {
					html
				}
				body {
					... on PrismicHomeDataBodyStatement {
						slice_type
						primary {
							texte {
								html
							}
						}
						items {
							image {
								# ...sharp
								alt
								url
								gatsbyImageData(width: 2000)
							}
						}
					}
					... on PrismicHomeDataBodyTestimonials {
						slice_type
						items {
							velo {
								document {
									... on PrismicVelo {
										uid
										lang
										type
										data {
											...veloTestimonial
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

// const IS_BROWSER = typeof window !== "undefined"

const PageHome = ({ data }) => {
	// const data = useMemo(() => {
	// 	if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData
	// 	// console.log(window.__PRISMIC_PREVIEW_DATA__)
	// 	return mergePrismicPreviewData({
	// 		staticData,
	// 		previewData: window.__PRISMIC_PREVIEW_DATA__,
	// 	})
	// }, [staticData])
	// // console.log(data)

	const {
		seo_title,
		seo_description,
		seo_image,
		// texte,
		velos,
		body,
		rebond_image,
		rebond_texte,
	} = data.prismicHome.data
	// console.log(data.prismicHome.data)

	useEffect(() => {
		document.addEventListener('scroll', _onScroll)

		return () => document.removeEventListener('scroll', _onScroll)
	})

	const _onScroll = () => {}

	return (
		<div className="home">
			<SEO
				pageTitle={seo_title}
				pageDescription={seo_description.text}
				pageBanner={seo_image.url}
				template="template-home has-hero"
				// pathname={location.pathname}
			/>

			<div className="velos">
				{velos.map((velo, i) => (
					<HomeHero key={i} input={velo} />
				))}
				{velos.map((velo, i) => (
					<HomeHeroBtn i={i} key={i} input={velo} />
				))}
			</div>

			{body && <Sections body={body} />}
			{rebond_texte && (
				<Rebonds
					input={{
						image: rebond_image,
						texte: rebond_texte,
					}}
				/>
			)}
		</div>
	)
}

export default PageHome
