import React from "react"

const Testimonial = ({ input }) => {
  // console.log(input.person)
  const hasPerson = input.person ? 'has-person' : ''
	return (
		<section className={`section section-testimonial ${hasPerson}`}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-xs-12 col-md-2">
            {input.person &&
						<div className="fS person ctx-default">{input.person}</div>
            }
					</div>
					<div className="col-xs-12 col-md-10">
						<blockquote
							className="quote fL"
							>{input.texte.text}</blockquote>
              {input.person &&
              <div className="fS person ctx-blog">{input.person}</div>
              }
						{/* <div
							className="texte fL quote"
							dangerouslySetInnerHTML={{
								__html: input.texte.html,
							}}
						></div> */}
					</div>
				</div>
			</div>
		</section>
	)
}

export default Testimonial
