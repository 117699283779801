/* eslint react/prop-types: 0, react/display-name: 0 */
import React from 'react'
import Helmet from 'react-helmet'
import PubSub from 'pubsub-js'
import { Layout } from './src/components/Layout'

import {
	PrismicPreviewProvider,
	componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import { _localizedSlug, linkResolver } from './src/core/utils'

import TemplateHome from './src/templates/page-home.jsx'
import TemplatePageBlog from './src/templates/page-blog.jsx'
import TemplatePageBlogPost from './src/templates/page-blog-post.jsx'
import TemplatePageBlogCategory from './src/templates/page-blog-category.jsx'
import TemplatePageEvents from './src/templates/page-events.jsx'
import TemplatePageVelos from './src/templates/page-velos.jsx'
import TemplatePageDefault from './src/templates/page-default.jsx'
import TemplateVelo from './src/templates/page-velo.jsx'
import TemplateVeloCategory from './src/templates/page-velos-category.jsx'

// import { withPrefix } from 'gatsby'
// const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>

// export default wrapPageElement

const _wrapRootElement = ({ element }) => {
	// return element;
	return (
		<>
			<Helmet>
				{/* <script>{`
          window.prismic = {
            'endpoint': 'https://victoire.cdn.prismic.io/api/v2'
          };
        `}</script>
				<script
					type="text/javascript"
					src="https://static.cdn.prismic.io/prismic.min.js?new=true"
				></script> */}
			</Helmet>
			<PrismicPreviewProvider
				repositoryConfigs={[
					{
						repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
						linkResolver: linkResolver,
						componentResolver: componentResolverFromMap({
							home: TemplateHome,
							pageBlog: TemplatePageBlog,
							pageBlogPost: TemplatePageBlogPost,
							pageBlogCategory: TemplatePageBlogCategory,
							pageEvents: TemplatePageEvents,
							pageVelos: TemplatePageVelos,
							pageDefault: TemplatePageDefault,
							velo: TemplateVelo,
							veloCategory: TemplateVeloCategory,
						}),
					},
				]}
			>
				{element}
			</PrismicPreviewProvider>
		</>
	)
}

const _wrapPageElement = ({ element, props }) => {
	// console.log(props)
	return <Layout {...props}>{element}</Layout>
}

const _onClientEntry = () => {
	console.log('_onClientEntry')
	// callAnalyticsAPI()
	document.body.classList.add('loading')
	const isTouch = 'ontouchstart' in window ? true : false

	if (isTouch) {
		document.documentElement.classList.add('touch')
	} else {
		document.documentElement.classList.add('no-touch')
	}

	window.onload = () => {
		console.log('window.onload')

		setTimeout(() => {
			document.body.classList.remove('loading')
		}, 1000)
	}

	const delay = 4 * 60 * 1000
	// const delay = 1000
	setTimeout(() => {
		PubSub.publish('SHOW_FORM')
	}, delay)
}

const _onPreRouteUpdate = ({ location, prevLocation }) => {
	// console.log(location, prevLocation)
	PubSub.publish('PRE_ROUTE_UPDATE', location)
}

const _onRouteUpdate = ({ location, prevLocation }) => {
	// console.log(location, prevLocation)
	PubSub.publish('ROUTE_UPDATE', location)
}

export {
	_wrapRootElement,
	_wrapPageElement,
	_onClientEntry,
	_onRouteUpdate,
	_onPreRouteUpdate,
}
