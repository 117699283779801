import React from 'react';
import PubSub from 'pubsub-js'

const VelosMore = () => {
  
  const _onClick = () => {
    PubSub.publish("LOAD_MORE")
  }

  return (
    <div className="velos-more curp" onClick={_onClick}>
      <div className="container-fluid">
        <div className="inner b-t">
          <div className="x xjc xac">
            <div className="text-center">
              <button>Afficher plus de vélos</button>
              <i className="icon icon-chevron-s fS"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VelosMore;