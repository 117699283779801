import React from 'react';
import { _getHexByName } from '../../core/utils'

const SectionCta = ({input}) => {
  // console.log(input)

  const style = {
    background: _getHexByName(input.primary.background)
  }
  return (
    <section className={"section section-qr noma"} style={style}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 hidden-sm"></div>
          <div className="col-xs col-md-8 ">
            <h2 
            className="fXL serif"
            data-title={input.primary.title.text}
            >{input.primary.title.text}</h2>
              {input.items.map((item, i) => (
              <div className="qr-item" key={i}>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 qr-item--title" >{item.qr_title}</div>
                  <div className="col-xs-12 col-sm-6 qr-item--texte">
                    <div
                      className="texte "
                      dangerouslySetInnerHTML={{
                        __html: item.qr_texte.html,
                      }} ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-2 hidden-sm"></div>
        </div>
				
			</div>
    </section>
  );
};

export default SectionCta;