import React, { useState } from 'react'
// import jsonp from "jsonp"
import PropTypes from 'prop-types'
import { getLocalizedText } from '../../core/utils'

const Form = props => {
	const [status, setStatus] = useState('initial')
	const [data, setData] = useState()

	const { fields, styles, className, formClassName } = props
	const messages = {
		...Form.defaultProps.messages,
		...props.messages,
	}

	const _handleSubmit = evt => {
		evt.preventDefault()
		// const { fields, action } = props;

		const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/
		const email = data['email']
		if (!regex.test(email)) {
			setStatus('error')
			return
		}
		_sendData()
	}

	const _sendData = async () => {
		setStatus('sending')
		// console.log(data)
		// const apiURL = "/.netlify/functions/sendmail"
		const apiURL = '/api/form'
		const body = {
			email: data['email'],
			subject: props.subject,
			data: data,
		}
		// console.log('apiURL', apiURL)
		// console.log('body', body)
		try {
			const response = await fetch(apiURL, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(body),
			})

			if (!response.ok) {
				return
			}
			console.log(response)
			if (response.ok) {
				setStatus('success')
				if (typeof props.callback === 'function') props.callback()

				setTimeout(() => {
					// setStatus("initial");
				}, 5000)
			}
		} catch (e) {
			setStatus('error')
		}
	}

	const _renderInput = input => (
		<input
			{...input}
			key={input.name}
			className=""
			onChange={e =>
				setData({
					...data,
					[e.target.name]: e.target.value,
				})
			}
			// defaultValue={state[input.name]}
		/>
	)

	const _renderTextarea = input => (
		<textarea
			{...input}
			key={input.name}
			className=""
			rows="1"
			onChange={e => {
				// console.log(e.target)
				e.target.style.height = '5px'
				e.target.style.height = e.target.scrollHeight + 'px'

				setData({
					...data,
					[e.target.name]: e.target.value,
				})
			}}
			// defaultValue={state[input.name]}
		/>
	)

	const getButtonMsg = () => {
		const messages = {
			...Form.defaultProps.messages,
			...props.messages,
		}

		// const { status } = this.state
		// console.log(status)
		switch (status) {
			case 'sending':
				return messages.sending
			case 'success':
				return messages.success
			case 'duplicate':
				return messages.duplicate
			case 'empty':
				return messages.empty
			case 'error':
				return messages.error
			default:
				return messages.button
		}
	}

	return (
		<form onSubmit={_handleSubmit} className={`${className} ${formClassName}`}>
			<div className="inner">
				<div className="form-row">
					{fields.map(input => {
						if (input.type === 'textarea') {
							return <div key={input.name}>{_renderTextarea(input)}</div>
						} else {
							return <div key={input.name}>{_renderInput(input)}</div>
						}
					})}
				</div>
				<div className="form-row">
					<button
						disabled={status === 'sending' || status === 'success'}
						type="submit"
						className=" has-icon chevron-e"
					>
						<span>
							{/* {status === "sending"
              ? messages.sending
              : messages.button} */}
							{getButtonMsg()}
						</span>
					</button>
				</div>
			</div>
			{/* <div className='msg-alert'>
        {status === "sending" && <p style={styles.sendingMsg}>{messages.sending}</p>}
        {status === "success" && <p style={styles.successMsg}>{messages.success}</p>}
        {status === "duplicate" && <p style={styles.duplicateMsg}>{messages.duplicate}</p>}
        {status === "empty" && <p style={styles.errorMsg}>{messages.empty}</p>}
        {status === "error" && <p style={styles.errorMsg}>{messages.error}</p>}
      </div> */}
		</form>
	)
}

Form.defaultProps = {
	messages: {
		sending: 'Sending...',
		success: 'Thank you!',
		error: 'An unexpected internal error has occurred.',
		empty: 'You must write an e-mail.',
		// duplicate: "Too many subscribe attempts for this email address",
		button: 'Sending!',
	},
	buttonClassName: '',
	styles: {
		sendingMsg: {
			color: '#0652DD',
		},
		successMsg: {
			color: '#009432',
		},
		duplicateMsg: {
			color: '#EE5A24',
		},
		errorMsg: {
			color: '#ED4C67',
		},
	},
}

Form.propTypes = {
	action: PropTypes.string,
	messages: PropTypes.object,
	fields: PropTypes.array,
	styles: PropTypes.object,
	className: PropTypes.string,
	buttonClassName: PropTypes.string,
}

export default Form
