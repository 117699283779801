import React from 'react';
import { Link } from "gatsby"
import { _getLocalizedText } from '../core/utils';

const ArticleAside = ({input}) => {
  const { 
    uid, 
    first_publication_date,
    last_publication_date 
  } = input

  const { 
    date,
    categories,
    auteur,
    credit_label,
    credit_texte
  } = input.data
  const hasCategory = categories["0"].category.document && categories["0"].category.document.data
  // console.log(input.lang, input.data.title.text)
// console.log(auteur)
  return (
    <aside className="fS">
      <ul className="metas">
        {auteur.document &&
        <li>
          <div className="meta-key">{_getLocalizedText("by")}</div>
          <div className="meta-val">{auteur.document.data.prenom.text}</div>
        </li>
        }
        {credit_texte.html &&
          <li className="credits">
            <div className="meta-key">{credit_label}</div>
            <div 
            className="meta-val" 
            dangerouslySetInnerHTML={{ __html: credit_texte.html }}></div>
          </li>
        }
        <li>
          <div className="meta-key">{_getLocalizedText("published")}</div>
          <div className="meta-val">
            {date &&
            <span>{`${_getLocalizedText("the")} ${date}`} </span> 
            }
            {hasCategory &&
            <span>
              <span>{_getLocalizedText("dans")} </span> 
              <Link to={`/categorie-blog/${categories["0"].category.document.uid}`}>{categories["0"].category.document.data.title.text}</Link>
            </span>
            }
          </div>
        </li>
        {last_publication_date !== date &&
        <li>
          <div className="meta-key">{_getLocalizedText("updated")}</div>
          <div className="meta-val">{`${_getLocalizedText("the")} ${last_publication_date}`}</div>
        </li>
        }
        
        <li>
          <Link to={`/blog/${uid}`} className="permalink"><span className="icon-link"></span></Link>
        </li>
      </ul>
    </aside>
  );
};

export default ArticleAside;