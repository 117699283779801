import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import VeloIndex from "./VeloIndex"
import { _localizedSlug } from "../core/utils"

const VeloCardSmall = ({ input, context }) => {
	// console.log(input)
	const {
		// uid,
		data: { index, title, image_principale },
	} = input

	return (
		<article className="card velo-card velo-card--small fS">
			<Link to={_localizedSlug(input)}>
				<div className="inner">
					{image_principale && image_principale.gatsbyImageData && (
						<div className="card-image">
							{/* <Img {...image_principale} Tag="figure" /> */}
							<GatsbyImage
								image={image_principale.gatsbyImageData}
								as="figure"
								alt={image_principale.alt || title.text}
							/>
							<div className="overlay"></div>
						</div>
					)}

					<div className="card-header ">
						{index && <VeloIndex index={index} />}
						{/* <h2 className="fS">{title.text}</h2> */}
						<div className="card-header--title">
							{context === "h2" && <h2 className="fS">{title.text}</h2>}
							{context === "h3" && <h3 className="fS">{title.text}</h3>}
							{context === "h4" && <h4 className="fS">{title.text}</h4>}
						</div>
					</div>
				</div>
			</Link>
		</article>
	)
}

export default VeloCardSmall
