import React, { Component } from "react"
import PubSub from "pubsub-js"

class Burger extends Component {
	constructor(props) {
		super(props)
		this.state = {
			active: false,
		}
	}

	componentWillUnmount() {
		PubSub.unsubscribe(this.tokenRouteUpdate)
		PubSub.unsubscribe(this.tokenBurger)
	}
	componentDidMount() {
		this.tokenRouteUpdate = PubSub.subscribe("ROUTE_UPDATE", (e, d) => {
			this.setState({
				active: false
			})
		})

		this.tokenBurger = PubSub.subscribe("BURGER.CLOSE", (e, d) => {
			this.setState(
				{
					active: false,
				},
				() => {
					PubSub.publish("BURGER", this.state.active)
				}
			)
		})
	}

	_onClick() {
		this.setState(
			{
				active: !this.state.active,
			},
			() => {
				PubSub.publish("BURGER", this.state.active)
				if(this.state.active){
					// document.body.classList.add("is-modal")
					// document.querySelector("header").classList.add("menu-mobile-active")
					// document.querySelector("header").classList.add("is-modal")
				}else{
					// document.body.classList.remove("is-modal")
					// document.querySelector("header").classList.remove("menu-mobile-active")
					// document.querySelector("header").classList.remove("is-modal")
				}
			}
		)
	}

	render() {
		const { active } = this.state
		const burgerClass = active ? "active" : ""
		return (
			<div
				className={"burger-wrapper sm-only curp fS " + burgerClass}
				onClick={() => this._onClick()}
				role="button"
				tabIndex={0}
			>
				{/* <div className="burger"></div> */}
				{active 
				? <span>Fermer</span>
				: <span>Menu</span>
				}
			</div>
		)
	}
}

export default Burger
