import React, { useState, useEffect, useContext } from 'react'
import { FiltersContext, WrapperContext } from './Layout'

const MenuFiltresItem = ({ input }) => {
	const _FiltersContext = useContext(FiltersContext)
	const { filters, dispatch } = _FiltersContext

	const _isActive = filters.length && filters.indexOf(input.uid) > -1
	const [active, setActive] = useState(_isActive)

	const _WrapperContext = useContext(WrapperContext)
	const { template, uid } = _WrapperContext

	useEffect(() => {
		// console.log("on ready")
		if (template === 'velo-categorie') {
			if (input.uid === uid) {
				// setActive(true)
				setTimeout(() => {
					// dispatch({type:"ADD", payload: uid})
					setActive(true)
				}, 250)
			}
		}
	}, [])

	//clean if deactivated elsewhere
	useEffect(() => {
		// console.log("test filters")
		// console.log(filters)
		const _isActive = filters.indexOf(input.uid) > -1
		setActive(_isActive)
		// if(filters.length && filters.indexOf(input.uid) == -1){
		//   setActive(false)
		// }
		// else{//no more filters in the array
		//   setActive(false)
		// }
		// setActive(_active)
	}, [filters])
	// console.log(input)
	// console.log(dispatch)
	useEffect(() => {
		// console.log("before dispatch", active, filters.indexOf(input.uid))
		// if(active)console.log(input.uid)
		if (active) {
			if (filters.indexOf(input.uid) > -1) {
				// console.log("exist already > remove it")
				// dispatch({type:"REMOVE", payload: input.uid})
			} else {
				dispatch({ type: 'ADD', payload: input.uid })
			}
		} else {
			dispatch({ type: 'REMOVE', payload: input.uid })
		}
		// active && filters.indexOf(input.uid) == -1//active and not exists
		// ? dispatch({type:"ADD", payload: input.uid})
		// : dispatch({type:"REMOVE", payload: input.uid})
	}, [active])

	const _onFilter = () => {
		setActive(!active)
	}

	// console.log(active)
	return (
		<button
			className={`btn primary rounded white ${active ? 'is-active' : ''}`}
			onClick={() => _onFilter()}
		>
			{input.data.title.text}
		</button>
	)
}

export default MenuFiltresItem
