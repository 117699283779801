import React, { useState, useContext, useEffect, useRef } from "react"
import { FiltersContext } from "./Layout"
import VeloCard from "./VeloCard"
import VelosMore from "./VelosMore"
import VelosNoResult from "./VelosNoResult"

const VelosGrid = ({ input, body }) => {
	const _FiltersContext = useContext(FiltersContext)
	const { filters } = _FiltersContext

	const grid = useRef()
	const more = useRef()

	const perPage = 20
	const [page, setPage] = useState(0)
	const [loadMore, setLoadMore] = useState(false)
	const [data, setData] = useState({
		paginatedData: input.edges.slice(0, perPage),
		// paginatedData: _getPaginatedData(),
		filteredData: [],
		// query: 'emptyQuery',
	})

	/**
	 * INFINITE SCROLL
	 */
	useEffect(() => {
		// const token = PubSub.subscribe("LOAD_MORE", () => {
		//   setLoadMore(true);
		// })
		// console.log("set listeners")
		setTimeout(() => {
			window.addEventListener("scroll", _handleScroll)
		}, 2000)
		return () => window.removeEventListener("scroll", _handleScroll)
		// return () => PubSub.unsubscribe(token)
	}, [])

	const _handleScroll = () => {
		// console.log("_handleScroll")
		if (!grid || !grid.current) return

		const { bottom } = grid.current.getBoundingClientRect()
		if (bottom <= window.innerHeight) {
			setLoadMore(true)
		}
	}

	const _reset = () => {
		setData({
			paginatedData: _getPaginatedData(0),
		})
	}

	useEffect(() => {
		if (loadMore) {
			setPage(page + 1)
		}
	}, [loadMore])

	useEffect(() => {
		// console.log(loadMore, filters)
		if (!loadMore) return
		if (filters.length > 0) return

		setLoadMore(false)

		const newPageData = _getPaginatedData(page)
		// console.log(data)
		// console.log(newPageData)
		if (newPageData.length > 0) {
			setData({
				paginatedData: [...data.paginatedData, ...newPageData],
			})
		} else {
			// console.log("herrrrrrrrrrrrrr")
			window.removeEventListener("scroll", _handleScroll)
			more.current.style.display = "none"
		}
	}, [page])

	const _getPaginatedData = (_page) => {
		// console.log("_getPaginatedData page", _page)
		const startIndex = _page * perPage
		const endIndex = startIndex + perPage
		return input.edges.slice(startIndex, endIndex)
	}

	/**
	 * FILTERED DATA
	 */
	useEffect(() => {
		// console.log(filters, page)
		if (filters.length) {
			setData({
				filteredData: _getFilteredData(),
			})
			// setPage(-1)
		} else {
			// console.log("else")
			_reset()
		}
	}, [filters])

	const _getFilteredData = () => {
		return input.edges.filter(({ node }) => {
			// console.log(node.data.categories)
			const categories = node.data.categories
				.filter(({ category }) => category.uid != null)
				.map(({ category }) => category.uid)
			// console.log(categories)
			let isIn
			if (categories) {
				// console.log(filters)
				// console.log(categories)

				const categoriesIn = categories.filter((category) => {
					// console.log(category, filters)
					// return category.indexOf(filters) > -1
					return filters.indexOf(category) > -1
				})
				// console.log(categoriesIn)
				// console.log(filters)
				isIn = categoriesIn.length === filters.length
				// isIn = JSON.stringify(categoriesIn) === JSON.stringify(filters);
			} else {
				isIn = false
			}

			return isIn
		})
	}

	const isFiltering = filters.length > 0
	const posts = isFiltering ? data.filteredData : data.paginatedData
	const noResults = posts === undefined || posts.length === 0
	// console.log(filters)
	// console.log("isFiltering", isFiltering)
	// console.log("filters length", filters.length)
	// console.log(posts)
	// console.log(page)
	// console.log(input)
	// console.log("page", page)
	return (
		<>
			<section className="grid" ref={grid}>
				<div className="container-fluid">
					{/* {page} */}
					{noResults && body && <VelosNoResult input={body[0]} />}

					<div className="row">
						{posts &&
							posts.map(({ node }, i) => (
								<div key={i} className="col-s-6 col-xs-12">
									<VeloCard input={node} context="h2" />
								</div>
							))}
					</div>
				</div>
			</section>
			<div ref={more}>
				<VelosMore />
			</div>
		</>
	)
}

export default VelosGrid
