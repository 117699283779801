import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const VelosNoResult = ({ input }) => {
	return (
		<div className="no-results">
			<div className="row">
				<div className="col-md-6 col-xs-12">
					{/* <Img {...input.items[0].image} Tag="figure" /> */}
				</div>
				<div className="col-md-6 col-xs-12">
					<div
						className="texte"
						dangerouslySetInnerHTML={{ __html: input.primary.texte.html }}
					/>
				</div>
			</div>
		</div>
	)
}

export default VelosNoResult
