import React from 'react';
import { Link } from "gatsby"
// import BgImg from "gatsby-background-image"
import VeloIndex from './VeloIndex'
import { _localizedSlug } from '../core/utils'

const HomeHero = ({i,input}) => {
  const {
    image,
    velo
  } = input

  // const top = i === 0 ?
  const style = {
    top: `calc(${i} * 100vh)`
  }
  // console.log(velo.document.data, typeof velo.document.data)
  if(typeof velo.document.data === undefined) return null
  return (
    <div className="home-hero--btn fS" style={style}>
      <Link to={_localizedSlug(velo.document)}>
        
        <div className="header">
          {velo && velo.document && velo.document.data &&
            <div className="x">
              <VeloIndex index={velo.document.data.index} />
              <h2 className="fS">{velo.document.data.title.text}</h2>
            </div>
          }
        </div>
      </Link>
    </div>
  );
};

export default HomeHero