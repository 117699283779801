import React from 'react';
// import ReactPlayer from 'react-player'
import VideoWrapper from '../ui/VideoWrapper'

const Video = ({input, autoplay}) => {
  // console.log(input)
  const config = {
    youtube: {
        playerVars: {
            showinfo: 0,
            iv_load_policy: 3,
            cc_load_policy: 0,
            modestbranding: 1,
            showinfo: 0,
            rel: 0,
            origin: 'https://victoire.netlify.app'
        }
    },
    vimeo: {
        title: false
    }
  }

  // const aspectRatio = input.embed.width/input.embed.height
  
  return (
    <section className="section section-video ssss">
			<div className="container-fluid">
        <div className="row">
          <div className="col-xs-12">
            <VideoWrapper input={input.embed} autoplay={autoplay} />
          </div>
        </div>
        <div className="row">
          <div className="hidden-xs col-md-2"></div>
          {input.texte &&
              <div className="col-xs-12 col-md-4">
                <div
                  className="texte "
                  dangerouslySetInnerHTML={{
                    __html: input.texte.html,
                  }} ></div>
              </div>
            }  
          <div className="hidden-xs col-xs-12 col-md-6"></div> 
        </div>
			</div>
		</section>
  );
};

export default Video;