import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'
import SEO from '../components/seo'
import Chapo from '../components/Chapo'
import Event from '../components/Event'
import Rebonds from '../components/Rebonds'

export const pageQuery = graphql`
	query Events($uid: String!, $locale: String!) {
		prismicPage(uid: { eq: $uid }, lang: { eq: $locale }) {
			_previewable
			data {
				...page
			}
		}
		allPrismicEvenement(
			sort: { fields: data___du, order: DESC }
			filter: { lang: { eq: $locale } }
		) {
			edges {
				node {
					uid
					...event
				}
			}
		}
	}
`

const IS_BROWSER = typeof window !== 'undefined'

const PageEvents = ({ data }) => {
	// const data = useMemo(() => {
	//   // If we're not in a browser (i.e. we're in SSR) or preview data has not been
	//   // set, use the non-preview static data.
	//   if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

	//   return mergePrismicPreviewData({
	//     staticData,
	//     previewData: window.__PRISMIC_PREVIEW_DATA__,
	//   })
	// }, [staticData])

	const { title, texte, template, rebond_image, rebond_texte } =
		data.prismicPage.data
	const { allPrismicEvenement: events } = data
	// console.log(events)
	return (
		<>
			<SEO
				pageTitle={title.text}
				pageDescription={texte.text}
				page={true}
				template={`template-${template} theme-or`}
			/>

			<div className="page-content">
				<Chapo title={title.text} texte={texte.html} />
				<div className="events">
					{events.edges.map(({ node }, i) => (
						<Event key={i} uid={node.uid} input={node.data} />
					))}
				</div>
			</div>

			{rebond_texte && (
				<Rebonds
					input={{
						image: rebond_image,
						texte: rebond_texte,
					}}
				/>
			)}
		</>
	)
}

export default PageEvents
