import React, { useEffect, useState } from 'react';
import scrollIntoView from 'scroll-into-view';
import PubSub from "pubsub-js"
import { throttle } from 'throttle-debounce'
import { _getLocalizedText, _headlineSectionIsInViewPort, _getTitleWithNumNormaliezd } from '../core/utils'


let menuAncre = []
const MenuAncre = () => {
  // const [menuAncre, setMenuAncre] = useState([])
  const [active, setActive] = useState()
  const [current, setCurrent] = useState(_getLocalizedText("sections"))
  const [currentMenuItem, setCurrentMenuItem] = useState("Sections")

  const sectionLabel = _getLocalizedText("sections")
  const etapeLabel = _getLocalizedText("etapes")
  // let isTemplateDefault

  useEffect(() => {
    _renderMenuAncre()

    // document.addEventListener("scroll", throttle(250, _onScroll))

    const tokenRouteUpdate = PubSub.subscribe("ROUTE_UPDATE", (e, d) => {
      // _renderLabel()
      _renderMenuAncre()
      setActive(false)
    })

    const tokenCurrentMenuItem = PubSub.subscribe("CURRENT_MENU_ITEM", (e, d) => {
      // console.log(d.title)
      setCurrentMenuItem(d.title)
      _renderLabel(d.title)
    })
    
		return () => {
      PubSub.unsubscribe(tokenRouteUpdate)
      PubSub.unsubscribe(tokenCurrentMenuItem)
      // document.removeEventListener("scroll", _onScroll)
    }
  }, [])

  useEffect(() => {
    if(active){
      const { height } = document.querySelector(".dropdown-content").getBoundingClientRect()
      // console.log(height)
      document.querySelector(".menu-n2").style.minHeight = (height+40)+"px"
    }else{
      document.querySelector(".menu-n2").style.minHeight = "auto"
    }
  }, [active])
  
  const _renderLabel = (title) => {
    const currentLabel = title.indexOf("propos") > -1 || title.indexOf("about") > -1
      ? sectionLabel
      : etapeLabel
      setCurrent(currentLabel)
  }

  const _renderMenuAncre = () => {
    // const headlines = Array.from(document.querySelectorAll(".section-headline"))
    const headlines = Array.from(document.querySelectorAll("h2[data-title]"))
    // console.log(headlines)
		const menuArr = headlines.map(el => {
      // console.log(el)
			return {
				title: el.dataset.title,
        ancre: el.getAttribute("id"),
        elem: el
			}
    })
    menuAncre = menuArr
    // console.log(menuAncre)
  }

	// const _onScroll = () => {
  //   // console.log(isTemplateDefault)
  //   let _current = null
  //   // return
  //   // console.log(menuAncre)
  //   menuAncre.forEach((el, i) => {
  //     const _isInViewport = _headlineSectionIsInViewPort(el.elem, i, menuAncre)
  //     // console.log(is, el.title)
  //     // const _isInViewport = isInViewport(el.elem)
  //     if(_isInViewport)_current = el
  //   })
  //   // console.log(_current)
  //   if(_current)setCurrent(_getTitleWithNumNormaliezd(_current.title))
  // }

  const _scrollTo = (e, i) => {
    e.preventDefault()
    setActive(false)

    const section = menuAncre[i].elem
    // console.log(section)
    scrollIntoView(section, {

      align: {
        top: 0,
        topOffset: 60 + 26
      }
    })
  }

  // console.log(menuAncre)
  return (
    <div className="menu-n2">
      <div className="row">
        {currentMenuItem &&
          <div className="col-md-2 col-xs-6">
            <div className="h1 fS">{currentMenuItem}</div>
          </div>
        }
        <div className="col-md-8 col-xs-6">
          <div className="menu-dropdown fS">
            <div className={`dropdown ${active ? 'active' : ''}`}>
              {active && 
                <div className="dropdown-close" onClick={() => setActive(false)}></div>
              }
              <div 
              className="dropdown-toggle " 
              onClick={() => setActive(!active)}>
                <div className={`has-icon ${active ? 'chevron-n' : 'chevron-s'} `}>
                  <span dangerouslySetInnerHTML={{ 
                    __html: current }} 
                  ></span>
                </div>
              </div>
              <div className="dropdown-content">
                <ul>
                {menuAncre.map((item, i) => (
                  <li key={i}>
                    <a href={`#${item.ancre}`} onClick={(e) => _scrollTo(e, i)}>
                      <div dangerouslySetInnerHTML={{ 
                        __html: _getTitleWithNumNormaliezd(item.title) }} 
                      ></div>
                    </a>
                  </li>
                ))}
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    
  );
};

export default MenuAncre;