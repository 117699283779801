import React, { useContext } from 'react'
import PrismicDOM from 'prismic-dom'
import { WrapperContext } from '../components/Layout'
const Elements = PrismicDOM.RichText.Elements

const locales = require('../../config/i18n')
// export function fileNameByUrl(url) {
// 	const decoded = decodeURIComponent(url)
// 	return decoded.substring(decoded.lastIndexOf("/") + 1)
// }

// export const _linkResolver = (doc) => {
// 	if (!doc) return "/"
// 	switch (doc.type) {
// 		case "page":
// 			return `/${doc.uid}`
// 		case "velo":
// 			return `/velo/${doc.uid}`
// 		case "categorie_velo":
// 			return `/categorie-velo/${doc.uid}`
// 		case "article":
// 			return `/blog/${doc.uid}`
// 		case "categorie_blog":
// 			return `/categorie-blog/${doc.uid}`
// 		case "evenements":
// 			return "evenements"
// 		default:
// 			return "/"
// 	}
// }
export const _getLocalizedText = text => {
	const _WrapperContext = useContext(WrapperContext)
	const { locale } = _WrapperContext
	// console.log("locale",locale, text)
	return locales[locale] && locales[locale][text] ? locales[locale][text] : text
}

export const _getTitleWithNumNormaliezd = title => {
	// console.log(title)
	const startsWidthNum = title.match(/^\d/)
	if (startsWidthNum) {
		const num = title.slice(0, 1)
		const texte = title.slice(1, title.length)
		return `<span class="num">${num}</span>${texte}`
	} else {
		return title
	}
}

export const _getInDateRange = (du, au) => {
	const now = new Date().getTime()
	const _du = new Date(du).getTime()

	if (du && au) {
		const _au = new Date(au).getTime()
		if (now <= _du) return true
		if (now <= _du && now <= _au) return true
		if (now >= _du && now <= _au) return true
	} else {
		return now <= _du
	}
}

export const _getHexByName = name => {
	switch (name) {
		case 'gris0':
			return '#F2F2F2'
		case 'gris100':
			return '#EDEAFA'
		case 'gris200':
			return '#DDDDDD'
		case 'gris300':
			return '#858585'
		case 'gris400':
			return '#454545'
		case 'gris500':
			return '#121212'
		case 'marron100':
			return '#EAE3D5'
		case 'marron200':
			return '#D0C19C'
		case 'marron300':
			return '#90856A'
		default:
			return '#ffffff'
	}
}

export const _headlineSectionIsInViewPort = (elem, index, headlines) => {
	let endSection
	if (headlines[index + 1]) {
		endSection = headlines[index + 1].elem
	} else {
		endSection = document.querySelector('.rebonds')
		if (!endSection) endSection = document.querySelector('footer')
	}
	// console.log(elem)
	// console.log("- ", endSection)
	const elemBounding = elem.getBoundingClientRect()
	const endSectionBounding = endSection.getBoundingClientRect()
	return (
		elemBounding.top <= window.innerHeight / 2 &&
		endSectionBounding.top >= window.innerHeight / 2
	)
}

export const isInViewport = elem => {
	const bounding = elem.getBoundingClientRect()
	return (
		bounding.top >= 0 &&
		bounding.left >= 0 &&
		bounding.bottom <=
			(window.innerHeight || document.documentElement.clientHeight) &&
		bounding.right <=
			(window.innerWidth || document.documentElement.clientWidth)
	)
}

export const _getThresholdTransparentByTemplate = template => {
	// return '.page-content'
	switch (template) {
		case 'home':
			return '.section-testimonials'
		default:
			return '.page-content'
	}
}

export const _getThresholdN2ByTemplate = template => {
	// console.log(template)
	switch (template) {
		case 'home':
			return '.section-testimonials'
		case 'default':
			return '.page-content'
		case 'velo':
			return '.page-content'
		case 'blog':
			return '.article'
		default:
			return '.page-content'
	}
}

// export const localizedHomeSlug = () => {

// }

export const _localizedSlug = node => {
	// console.log(node)
	if (!node || !node.uid) return '/'
	const link = linkResolver(node)

	return locales[node.lang].default
		? `${link}`
		: `/${locales[node.lang].path}${link}`
}

// export const _localizedSlugWithPrefix = (node) => {

// }

export const linkResolver = doc => {
	console.log('linkResolver', doc)
	if (doc && doc.type === 'page') return `/${doc.uid}`
	if (doc) {
		switch (doc.type) {
			case 'velo':
				return `/velo/${doc.uid}`
			case 'article':
				return `/blog/${doc.uid}`
			case 'page':
				return `/${doc.uid}`
			default:
				if (doc.uid) {
					return `/${doc.uid}`
				}
				return `/${doc.type}`
		}
	}

	return '/'
}

export const _htmlSerializer = (type, element, content, children) => {
	// console.log(type)
	// console.log(element)
	// console.log(content)
	// console.log(children)
	switch (type) {
		case Elements.paragraph:
			return `<p>${children.join('')}</p>`
		default:
			return null
	}
}
