import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Statement = ({ input }) => {
	const rand = Math.round(Math.random() * (input.items.length - 1))
	const { image } = input.items[rand]
	// console.log(image)
	return (
		<section className="section section-statement noma fL h-screen">
			<div className="inner">
				{/* <Img
					{...image}
					Tag="figure"
					style={{
						height: "100vh",
					}}
				/> */}
				{/* <pre>{JSON.stringify(image, false, 2)}</pre> */}

				<GatsbyImage
					image={image.gatsbyImageData}
					className="h-screen"
					alt="Statement"
				/>
				<div className="gradient"></div>
				<div className="psa ">
					<div className="row">
						<div className="col-md-2 hidden-sm"></div>
						<div className="col-md-10 col-xs-12">
							<div
								className="texte fXL"
								dangerouslySetInnerHTML={{ __html: input.primary.texte.html }}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Statement
