import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Collaborateurs = ({ input }) => {
	// console.log(input)

	return input[0].collaborateur.document == null ? null : (
		<section className={"section section-collaborateurs"}>
			<div className="container-fluid">
				<div className="row">
					{input.map(({ collaborateur }, i) => {
						const { data } = collaborateur.document
						return (
							<article
								key={i}
								className="col-xs-12 col-md-4 card card--collaborateur "
							>
								{data.image && data.image.gatsbyImageData && (
									<div className="card-image">
										<div className="inner">
											{/* <Img {...data.image} /> */}
											<GatsbyImage
												image={data.image.gatsbyImageData}
												alt={`${data.prenom.text} ${data.nom.text}`}
											/>
											{/* <pre>{JSON.stringify(data)}</pre> */}
										</div>
									</div>
								)}
								<div className="card-header">
									<h3 className="name">
										{`${data.prenom.text} ${data.nom.text}`}
									</h3>

									<div className="role fS">{data.role}</div>
									{data.lien && data.lien.url && (
										<a
											className="fS"
											rel="noopener noreferrer"
											target="_blank"
											href={data.lien.url}
										>
											{data.lien_label}
										</a>
									)}
								</div>
							</article>
						)
					})}
				</div>
			</div>
		</section>
	)
}

export default Collaborateurs
