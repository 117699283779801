import React, { createContext, useReducer, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
	_getThresholdN2ByTemplate,
	_getThresholdTransparentByTemplate,
} from '../core/utils'
import Header from './Header'
import Footer from './Footer'
import FormDelais from './FormDelais'

// import Annonce from "./Annonce"

const WrapperContext = createContext()
const FiltersContext = createContext([{}, () => {}])
const initialFiltersState = []

function reducer(state, action) {
	switch (action.type) {
		case 'ADD':
			return [...state, action.payload]
		case 'REMOVE':
			return state.filter(item => item !== action.payload)
		case 'REMOVE_ALL':
			return []
		default:
			throw new Error()
	}
}

const query = graphql`
	query {
		settingsFr: prismicSiteSettings(lang: { eq: "fr-fr" }) {
			...settings
		}
		settingsEn: prismicSiteSettings(lang: { eq: "en-gb" }) {
			...settings
		}
		annonceFr: prismicAnnonce(lang: { eq: "fr-fr" }) {
			...annonce
		}
		annonceEn: prismicAnnonce(lang: { eq: "en-gb" }) {
			...annonce
		}
	}
`

const Layout = ({
	children,
	pageContext: {
		uid,
		locale,
		pageInfos,
		template,
		MENU_ANCRE,
		HEADER_TRANSPARENT,
		TOTAL_VELOS,
	},
}) => {
	// console.log(template)
	const { settingsFr, settingsEn, annonceFr, annonceEn } = useStaticQuery(query)

	const settings = locale === 'fr-fr' ? settingsFr : settingsEn
	// console.log(settingsFr.data)
	// console.log(settingsEn.data)
	const annonce = locale === 'fr-fr' ? annonceFr : annonceEn

	const [filters, dispatch] = useReducer(reducer, initialFiltersState)
	const [showForm, setShowForm] = useState()

	const HAS_MENU_ANCRE = MENU_ANCRE || template === 'evenements'
	const HAS_VELOS_FILTERS =
		template === 'velos' || template === 'velo-categorie'
	const HAS_MENU_VELO = template === 'velo'
	const HAS_MENU_CATEGORIES =
		template === 'blog' ||
		template === 'categorie-blog' ||
		template === 'blog-post'

	const HAS_HEADER_TRANSPARENT = HEADER_TRANSPARENT
	const THRESHOLD_N2 = _getThresholdN2ByTemplate(template)
	const THRESHOLD_TRANSAPRENT = _getThresholdTransparentByTemplate(template)

	useEffect(() => {
		_handleDevice()
	}, [])

	const _handleDevice = () => {
		// const userAgent =
		//   typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

		const isSafari =
			navigator.vendor &&
			navigator.vendor.indexOf('Apple') > -1 &&
			navigator.userAgent &&
			navigator.userAgent.indexOf('CriOS') == -1 &&
			navigator.userAgent.indexOf('FxiOS') == -1

		// console.log({ isSafari })
		if (isSafari) {
			document.documentElement.classList.add('is-safari')
		}
	}

	useEffect(() => {
		const token = PubSub.subscribe('SHOW_FORM', e => {
			// console.log(e)
			setShowForm(true)
		})

		return () => {
			PubSub.unsubscribe(token)
		}
	}, [showForm])
	// console.log("HAS_MENU_ANCRE", HAS_MENU_ANCRE, template)
	// console.log("THRESHOLD_N2", template, THRESHOLD_N2)
	return (
		<WrapperContext.Provider
			value={{
				uid,
				template,
				locale,
				pageInfos,
				settings,
				annonce,
				THRESHOLD_N2,
				THRESHOLD_TRANSAPRENT,
				HAS_MENU_ANCRE,
				HAS_HEADER_TRANSPARENT,
				HAS_VELOS_FILTERS,
				HAS_MENU_VELO,
				HAS_MENU_CATEGORIES,
				TOTAL_VELOS,
			}}
		>
			<FiltersContext.Provider value={{ filters, dispatch }}>
				<div id="page">
					<Header />

					<main>{children}</main>
					<Footer />
					{showForm && <FormDelais />}
				</div>
			</FiltersContext.Provider>
		</WrapperContext.Provider>
	)
}

export { WrapperContext, FiltersContext, Layout }
