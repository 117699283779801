import React from 'react';
import { Link } from "gatsby"
import VeloCard from './VeloCard';
import { _getLocalizedText, _localizedSlug } from "../core/utils"

const RebondsVeloCategory = ({ categories, velos, limit=3 }) => {
  // console.log(velos.nodes[0])
  const locale = velos.nodes[0]
  ? velos.nodes[0].lang
  :  "fr-fr"
  // const _getPostCategories = (post) => {
  //   return post.categories.map(el => el.category.uid)
  // }
  const _getCategoryNameBySlug = (categoryUid) => {
    // console.log("category", categoryUid)
    let categoryName = ''

    velos.nodes.forEach(velo => {
      velo.data.categories.forEach(({category}) => {
        if(category.uid === categoryUid)
          categoryName = category.document.data.title.text
      })
    })
    return categoryName
   
  }

  const _getPostsByCategory = (category) => {
    // console.log("category", category)
    return velos.nodes.filter(velo => {
      const categoriesUid = velo.data.categories.map(el => el.category.uid)
      return categoriesUid.indexOf(category) > -1
    })
  }
  
  const _reduceByCategories = categories.map(category => {
    const veloInCat = _getPostsByCategory(category).slice(0, limit)
    // console.log(velos)
    return {
      categoryUid: category,
      categoryName: _getCategoryNameBySlug(category),
      data: veloInCat
    }
  })

  const _slug = (category) => {
    return locale === "fr-fr"
    ? `/categorie-velo/${category.categoryUid}`
    : `/en/categorie-velo/${category.categoryUid}`
  }

  const _goToCat = (el) => {

  } 
  // console.log(_reduceByCategories)
  //`/categorie-velo/${el.categoryUid}`
  return (
    <section className="redonds-velo">
      <div className="container-fluid">
      <h2 className="fM">{_getLocalizedText("otherBikes")}</h2>
      {_reduceByCategories.map((el, i ) => (
        <div className="redond-row" key={i}>
          {/* <Link to={_slug(el)} className="btn primary rounded fS" onClick={(e) => _goToCat(el)}>{el.categoryName}</Link> */}
          <div className="btn primary rounded fS ">{el.categoryName}</div>
          <div className="row">
            {el.data.map((data, i) => (
              <div key={i} className="col-md-4 col-sm-4 col-xs-12">
              <VeloCard input={data} context="h3" />
              </div>
            ))}
          </div>
        </div>
      ))}
      </div>
    </section>
  );
};

export default RebondsVeloCategory;