import React from "react"
import scrollIntoView from 'scroll-into-view';
import { _getLocalizedText } from '../../core/utils'

const BackToTop = () => {
	
	const _click = () => {
		// if (window) {
		// 	window.scrollTo(0, 0)
		// }
		// scrollIntoView(document.querySelector("header"), {
		scrollIntoView(document.querySelector("#___gatsby"), {
			// debug: true
		})
	}

	return (
		<div className="back-to-top curp has-icon arrow-n" onClick={_click}>
			{_getLocalizedText('backTop')}
			{/* <span className="icon-arrow-n"></span> */}
		</div>
	)
}


export default BackToTop
