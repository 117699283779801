import React from 'react'

import Headline from './Headline'
import Texte from './Texte'
import Image from './Image'
import ImagesTexte from './ImagesTexte'
import Video from './Video'
import VideoTexte from './VideoTexte'
import ListeTexte from './ListeTexte'
import Accordeon from './Accordeon'
import Collaborateurs from './Collaborateurs'
import Testimonial from './Testimonial'
import Testimonials from './Testimonials'

import Statement from './Statement'
import SectionCta from './SectionCta'
import SectionQR from './SectionQR'
import SectionTitreTexte from './SectionTitreTexte'

// import "./sections.scss"

const Sections = props => {
	const { body, context } = props
	// console.log(context)
	// console.table(body)
	const sections = body.map((section, i) => {
		// console.log(section.slice_type)
		switch (section.slice_type) {
			case 'headline':
				return <Headline key={i} input={section.primary} />
			case 'full_image':
				return <FullImage key={i} input={section.primary.image} />
			case 'texte':
				return <Texte key={i} input={section.primary} />
			case 'image':
				return <Image key={i} input={section.primary} />
			case 'images_texte':
				return <ImagesTexte key={i} input={section} />
			case 'video':
				return (
					<Video
						key={i}
						input={section.primary}
						autoplay={context !== 'blog'}
					/>
				)
			case 'video_texte':
				return (
					<VideoTexte
						key={i}
						input={section.primary}
						autoplay={context !== 'blog'}
					/>
				)
			case 'liste_texte':
				return <ListeTexte key={i} input={section.items} />
			case 'accordeon':
				return <Accordeon key={i} input={section.items} />
			case 'collaborateurs':
				return <Collaborateurs key={i} input={section.items} />
			case 'section_cta':
				return <SectionCta key={i} input={section.primary} />
			case 'section_qr':
				return <SectionQR key={i} input={section} />
			case 'section_titre_texte':
				return <SectionTitreTexte key={i} input={section.primary} />
			case 'testimonial':
				return <Testimonial key={i} input={section.primary} />
			case 'testimonials':
				return <Testimonials key={i} input={section.items} />
			case 'statement':
				return <Statement key={i} input={section} />
			case 'separateur':
				return <hr key={i} />
			default:
				return null
		}
	})

	return <div className="sections">{sections}</div>
}

export default Sections
