import React from 'react';
import { Link } from "gatsby"
import { _getLocalizedText } from '../core/utils';

const BogPagination = ({input}) => {
  // console.log(input)
  const { 
    currentPage,
    blogNumberOfPages
  } = input
  return (
    <div className="blog-pagination">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 hidden-sm"></div>
          <div className="col-md-6 col-xs-12">
            <div className="x xjb b-t">
              <div className="prev">
              {currentPage > 1 &&
                <Link 
                to={`/blog/${currentPage - 1 > 1 ? currentPage - 1 : ''}`}
                className="has-icon chevron-w ">{_getLocalizedText("prevBlog")}</Link>
              }
              </div>
              <div className="next text-right">
              {currentPage < blogNumberOfPages &&
                <Link 
                to={`/blog/${currentPage + 1}`}
                className="has-icon chevron-e ">{_getLocalizedText("nextBlog")}</Link>
              }
              </div>
            </div>
          </div>
          <div className="col-md-4 hidden-sm"></div>
        </div>
        
      </div>
    </div>
  );
};

export default BogPagination;