import React from "react"
import Form from '../ui/Form'
import { _getLocalizedText } from '../../core/utils'

const ListeTexte = ({ input }) => {
	// console.log(input)
	return (
		<section className={"section section-liste-texte"}>
			<div className="container-fluid">
				<div className="row">
          <div className="col-md-2 hidden-xs"></div>
          <div className="col-md-8 col-xs-12">
            {input.map((item, i) => (
              <div className="liste-texte-item" key={i}>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 liste-texte-item--title" >{item.liste_title.text}</div>
                  <div className="col-xs-12 col-sm-6 liste-texte-item--texte">
                    {item.liste_texte.html.indexOf("[newsletter]") > -1 &&
                      <Form 
                        action=""
                        subject="demande d'informations"
                        fields={[
                          {
                            name: "email",
                            placeholder: _getLocalizedText("emailPlaceHolder"),
                            type: "email",
                            required: true,
                          },
                          {
                            name: "message",
                            placeholder: _getLocalizedText("yourMessage"),
                            type: "textarea",
                            required: true,
                          },
                        ]}
                        messages={{
                          sending: _getLocalizedText("sending"),
                          success: _getLocalizedText("success"),
                          error: _getLocalizedText("error"),
                          empty: _getLocalizedText("empty"),
                          duplicate: _getLocalizedText("duplicate"),
                          button: _getLocalizedText("send"),
                        }}
                        className="form"
                        // formClassName={display}
                        />
                    }
                    {item.liste_texte.html !== "<p>[newsletter]</p>" &&
                    <div
                      className="texte "
                      dangerouslySetInnerHTML={{
                        __html: item.liste_texte.html,
                      }} ></div>
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-2 hidden-xs"></div>
				</div>
			</div>
		</section>
	)
}

export default ListeTexte
