import React from "react"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { _getLocalizedText, _localizedSlug } from "../core/utils"

const ArticleCard = ({ input }) => {
	if (!input.document) return null
	const { uid, first_publication_date } = input.document
	const { title, image, auteur, categories } = input.document.data
	const hasCategory =
		categories["0"].category.document && categories["0"].category.document.data
	// console.log(input.document.data)
	return (
		<article className="card article-card fS">
			<div className="row">
				{image && image.fluid && (
					<div className="col-md-4 col-xs-12">
						<Link to={`/blog/${uid}`}>
							<div className="card-image">
								{/* //<Img {...image} Tag="figure" /> */}
								<div className="overlay"></div>
							</div>
						</Link>
					</div>
				)}

				<div className="col-md-6 col-xs-12">
					<h3 className="fL">
						<Link to={`/blog/${uid}`}>{title.text}</Link>
					</h3>
					<ul className="metas">
						{auteur.document && (
							<li>
								<div className="meta-key">{_getLocalizedText("by")}</div>
								<div className="meta-val">
									{auteur.document.data.prenom.text}
								</div>
							</li>
						)}

						<li>
							<div className="meta-key">{_getLocalizedText("published")}</div>
							<div className="meta-val">
								<span>
									{`${_getLocalizedText("the")} ${first_publication_date}`}{" "}
								</span>
								{hasCategory && (
									<span>
										<span>{_getLocalizedText("dans")} </span>
										<Link
											to={`/categorie-blog/${categories["0"].category.document.uid}`}
										>
											{categories["0"].category.document.data.title.text}
										</Link>
									</span>
								)}
							</div>
						</li>
					</ul>
				</div>
			</div>
		</article>
	)
}

export default ArticleCard
