import React from 'react';
import VeloIndex from './VeloIndex'
import ArticleCard from './ArticleCard';
import { _getLocalizedText } from "../core/utils"

const RebondsBlog = ({index, articles}) => {
  // console.log(articles[0].article.document )
  // const _WrapperContext = useContext(WrapperContext)
  // const { locale } = _WrapperContext
  // console.log(locale)
  // console.log(_getLocalizedText("the"))
  // return null
  return !articles && !articles[0].article.document 
  ? null
  : (
    <section className="redonds-blog">
      <div className="container-fluid">        
        <h2 className="fM">
          <span>{_getLocalizedText("the")}</span> <VeloIndex index={index} /> <span>{_getLocalizedText("onTheBlog")}</span>
        </h2>
        {articles.map(({article}, i) => (
          <ArticleCard input={article} key={i} />
        ))}
        
      </div>
    </section>
  );
};

export default RebondsBlog;