import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'

import SEO from '../components/seo'
import Chapo from '../components/Chapo'
import Article from '../components/Article'
import BogPagination from '../components/BogPagination'
import Rebonds from '../components/Rebonds'

export const pageQuery = graphql`
	query Blog($skip: Int!, $limit: Int!, $locale: String!) {
		prismicPage(data: { template: { eq: "blog" } }, lang: { eq: $locale }) {
			_previewable
			data {
				...page
			}
		}
		allPrismicArticle(
			limit: $limit
			skip: $skip
			filter: { lang: { eq: $locale } }
			sort: { fields: data___date, order: DESC }
		) {
			edges {
				node {
					...article
				}
			}
		}
	}
`

const IS_BROWSER = typeof window !== 'undefined'

const PageBlog = ({ pageContext, data }) => {
	// console.log(props)
	// const data = useMemo(() => {
	//   if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

	//   return mergePrismicPreviewData({
	//     staticData,
	//     previewData: window.__PRISMIC_PREVIEW_DATA__,
	//   })
	// }, [staticData])
	// console.log(data.prismicPage.data)
	const { title, texte, rebond_image, rebond_texte } = data.prismicPage.data
	const { allPrismicArticle: articles } = data
	// console.log(data)
	return (
		<>
			<SEO
				pageTitle={title.text}
				pageDescription={texte.text}
				page={true}
				template="template-blog theme-or"
			/>

			<div className="page-content">
				<Chapo title={title.text} texte={texte.html} />
				{/* <div className="container-fluid">
					<hr/>
				</div> */}
				{articles.edges.map(({ node }, i) => (
					<Article key={i} input={node} single={false} />
				))}

				<BogPagination input={pageContext} />
			</div>

			{rebond_texte && (
				<Rebonds
					input={{
						image: rebond_image,
						texte: rebond_texte,
					}}
				/>
			)}
		</>
	)
}

export default PageBlog
