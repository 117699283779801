module.exports = doc => {
	switch (doc.type) {
		case 'page':
			// console.log('src/core/linkResolver.js', doc.type, doc.uid)
			return `/${doc.uid}`
		case 'velo':
			return `/velo/${doc.uid}`
		case 'categorie_velo':
			return `/categorie-velo/${doc.uid}`
		case 'article':
			return `/blog/${doc.uid}`
		case 'categorie_blog':
			return `/categorie-blog/${doc.uid}`
		case 'evenements':
			return 'evenements'
		// case 'home':
		//   return '/'
		default:
			return '/'
	}
}
