import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'
// import Img from "gatsby-background-image"
//import PubSub from 'pubsub-js';
import SEO from '../components/seo'
import Hero from '../components/sections/Hero'
import PageHeader from '../components/PageHeader'
import VeloIndex from '../components/VeloIndex'
// import VeloAside from "../components/VeloAside"
// import Img from "gatsby-background-image"
import ChapoVelo from '../components/ChapoVelo'
import Sections from '../components/sections'
import RebondsVeloCategory from '../components/RebondsVeloCategory'
import RebondsBlog from '../components/RebondsBlog'
import Rebonds from '../components/Rebonds'

export const pageQuery = graphql`
	query Velo($uid: String!, $locale: String!, $categories: [String]!) {
		prismicVelo(uid: { eq: $uid }, lang: { eq: $locale }) {
			_previewable
			data {
				...velo
				rebond_blog {
					article {
						lang
						uid
						document {
							... on PrismicArticle {
								...articleCard
							}
						}
					}
				}
			}
		}
		allPrismicVelo(
			filter: {
				uid: { ne: $uid }
				lang: { eq: $locale }
				data: {
					categories: { elemMatch: { category: { uid: { in: $categories } } } }
					visible_rebonds: { ne: false }
				}
			}
		) {
			nodes {
				uid
				lang
				type
				data {
					...veloCard
					categories {
						category {
							uid
							document {
								... on PrismicCategorieVelo {
									data {
										title {
											text
										}
									}
								}
							}
						}
					}
				}
			}
		}
		prismicRebondVelo(lang: { eq: $locale }) {
			data {
				image {
					# ...sharp
					alt
					url
					gatsbyImageData(width: 2000)
				}
				texte {
					html
				}
			}
		}
	}
`

const IS_BROWSER = typeof window !== 'undefined'

const PageVelo = ({ pageContext: { categories }, data }) => {
	// const data = useMemo(() => {
	// 	// If we're not in a browser (i.e. we're in SSR) or preview data has not been
	// 	// set, use the non-preview static data.
	// 	if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

	// 	return mergePrismicPreviewData({
	// 		staticData,
	// 		previewData: window.__PRISMIC_PREVIEW_DATA__,
	// 	})
	// }, [staticData])

	const {
		index,
		title,
		texte,
		image_hero,
		image_hero_mobile,
		livraison,
		equipe,
		body,
		rebond_blog,
	} = data.prismicVelo.data
	const rebondsVelo = data.allPrismicVelo
	const rebond = data.prismicRebondVelo.data
	// console.log("image_hero", image_hero)
	// console.log("image_hero_mobile", image_hero_mobile)
	// const hasRebondsVelo =
	return (
		<>
			<SEO
				pageTitle={title.text}
				pageDescription={texte.text}
				pageBanner={image_hero.url}
				page={true}
				template="template-velo has-hero"
			/>

			{image_hero && <Hero input={image_hero} alt={image_hero_mobile} />}

			<div className="page-content">
				<PageHeader index={index} h1={title.text} />
				<ChapoVelo
					title=""
					texte={texte.html}
					metas={{ livraison: livraison, equipe: equipe }}
				/>
				<Sections body={body} context="default" />
			</div>
			<div className="page-footer">
				{rebond_blog && rebond_blog[0] && rebond_blog[0].article.uid && (
					<RebondsBlog index={index} articles={rebond_blog} />
				)}
				{rebondsVelo && rebondsVelo.nodes && (
					<RebondsVeloCategory categories={categories} velos={rebondsVelo} />
				)}
			</div>

			{rebond && (
				<Rebonds
					input={{
						image: rebond.image,
						texte: rebond.texte,
					}}
				/>
			)}
		</>
	)
}

export default PageVelo
