import React, { useMemo, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"
//import PubSub from 'pubsub-js';
import SEO from "../components/seo"
import Chapo from "../components/Chapo"
import VelosGrid from "../components/VelosGrid"
import Rebonds from "../components/Rebonds"
import { FiltersContext } from "../components/Layout"

export const pageQuery = graphql`
	query VelosCategory($uid: String!, $locale: String!) {
		page: prismicPage(
			data: { template: { eq: "velos" } }
			lang: { eq: $locale }
		) {
			data {
				title {
					text
				}
				texte {
					html
					text
				}
				rebond_image {
					# ...sharp
					alt
					url
					gatsbyImageData(width: 2000)
				}
				rebond_texte {
					html
				}
			}
		}
		category: prismicCategorieVelo(uid: { eq: $uid }, lang: { eq: $locale }) {
			data {
				title {
					text
				}
			}
		}
		velos: allPrismicVelo(
			filter: {
				data: { categories: { elemMatch: { category: { uid: { eq: $uid } } } } }
				lang: { eq: $locale }
			}
		) {
			edges {
				node {
					uid
					type
					lang
					data {
						...veloCard
					}
				}
			}
		}
	}
`

const IS_BROWSER = typeof window !== "undefined"

const PageVeloCategory = ({ pageContext: { uid, TOTAL_VELOS }, data }) => {
	// console.log(pageContext)
	// const data = useMemo(() => {
	// 	// If we're not in a browser (i.e. we're in SSR) or preview data has not been
	// 	// set, use the non-preview static data.
	// 	if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

	// 	return mergePrismicPreviewData({
	// 		staticData,
	// 		previewData: window.__PRISMIC_PREVIEW_DATA__,
	// 	})
	// }, [staticData])

	const limit = 10
	const { title, texte, rebond_image, rebond_texte } = data.page.data
	const { category, velos } = data

	const texteHtmlFormated = texte.html.replace("[%dernier%]", TOTAL_VELOS)
	const texteTextFormated = texte.text.replace("[%dernier%]", TOTAL_VELOS)

	// const _FiltersContext = useContext(FiltersContext)
	// const { filters, dispatch } = _FiltersContext
	// useEffect(() => {
	//   console.log("category", category)
	//   console.log("uid", uid)
	//   // dispatch({type:"ADD", payload: uid})
	// }, [])

	return (
		<>
			<SEO
				pageTitle={title.text}
				pageDescription={texteTextFormated}
				page={true}
				template="template-velos"
			/>

			<div className="page-content">
				<Chapo title={title.text} texte={texteHtmlFormated} />

				{/* <section className="grid">
					<script id="velos-category--data">
						{JSON.stringify(velos)}
					</script>
					<div className="container-fluid">
						<div className="row">
							{velos.edges.map(({ node }, i) => (
								<div key={i} className="col-md-6">
									<VeloCard input={node} context="h2" />
								</div>
							))}
						</div>
					</div>
				</section> */}
				{velos && <VelosGrid input={velos} body={null} />}
			</div>
			{rebond_texte && (
				<Rebonds
					input={{
						image: rebond_image,
						texte: rebond_texte,
					}}
				/>
			)}
		</>
	)
}

export default PageVeloCategory
