import React from 'react'
import { Link } from 'gatsby'
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Sections from './sections'
import ArticleAside from './ArticleAside'
import RebondsVelo from '../components/RebondsVelo'
import { _getLocalizedText, _localizedSlug } from '../core/utils'

const Article = ({ input, single }) => {
	const { lang } = input
	const {
		title,
		texte,
		image,
		// categories,
		rebond_velo,
		body,
	} = input.data

	// console.log(categories[0].category.document)
	// console.log(image)
	return (
		<article className="article">
			<div className="container-fluid">
				<hr />
			</div>
			<div className="blog-header">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-2 hidden-xs"></div>
						<div className="col-md-6 col-xs-12">
							{single ? (
								<h1 className="fL">{title.text}</h1>
							) : (
								<h2 className="fL">{title.text}</h2>
							)}
						</div>
						<div className="col-md-2 col-xs-12 ">
							{/* <ArticleAside input={input} /> */}
						</div>
					</div>
				</div>
			</div>
			{/* blog-header */}

			<div className="blog-content">
				{image && image.gatsbyImageData && (
					<div className="blog-content--hero">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-1"></div>
								<div className="col-xs-12 col-sm-9 col-md-8 col-lg-8 ">
									{/* //<Img {...image} Tag="figure" /> */}
									<GatsbyImage image={image.gatsbyImageData} alt={title.text} />
								</div>
							</div>
						</div>
					</div>
				)}

				<div className="psa h100 sm-only">
					<div className="container-fluid h100">
						<div className="row end-xs h100">
							<div className="col-sm-3 col-xs-12">
								<ArticleAside input={input} />
							</div>
						</div>
					</div>
				</div>

				{texte && texte.text && texte.html && (
					<div className="blog-content--texte">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-2"></div>
								<div className="col-xs-12 col-md-8 ">
									<div
										className="texte"
										dangerouslySetInnerHTML={{ __html: texte.html }}
									></div>
								</div>
								<div className="col-md-2"></div>
							</div>
						</div>
					</div>
				)}

				<div className="psa h100 hidden-sm">
					<div className="container-fluid h100">
						<div className="row end-xs h100">
							<div className="col-sm-3 col-xs-12">
								<ArticleAside input={input} />
							</div>
						</div>
					</div>
				</div>

				{body && <Sections body={body} context="blog" />}
			</div>
			{/* blog-content */}
			<div className="blog-footer">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-2"></div>
						<div className="col-xs-12 col-md-8 ">
							{rebond_velo && <RebondsVelo input={rebond_velo} />}

							{single && (
								<div className="b-t">
									<Link
										to={_localizedSlug({
											type: 'page',
											uid: 'blog',
											lang: lang,
										})}
										className="has-icon chevron-w"
									>
										{_getLocalizedText('backBlog')}
									</Link>
								</div>
							)}
						</div>
						<div className="col-md-2"></div>
					</div>
				</div>

				{/* <div className="container-fluid">
					<div className="row">
						<div className="hidden-sm col-md-2"></div>
						<div className="col-md-8-- col-xs-12">
							{rebond_velo && <RebondsVelo input={rebond_velo}/>}

							{single &&
							<div className="b-t">
								<Link
								to={_localizedSlug({type:"page", uid:"blog", lang:lang})}
								className="has-icon chevron-w">{_getLocalizedText("backBlog")}</Link>
							</div>
							}
						</div>
					</div>
				</div> */}
			</div>
		</article>
	)
}

export default Article
