import React, { useContext } from 'react'
// import { Link } from "gatsby"
// import PubSub from "pubsub-js"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { WrapperContext } from './Layout'
import MenuItem from './MenuItem'
import LangSwitcher from './ui/LangSwitcher'
import NewsletterWrapper from './ui/NewsletterWrapper'
import { _getLocalizedText } from '../core/utils'

const MenuModal = ({ secondaire, tertiaire, quaternaire, active }) => {
	// console.log(secondaire)
	const _WrapperContext = useContext(WrapperContext)
	const { locale, settings } = _WrapperContext
	// console.log(settings.data)
	const _className = active ? 'is-active' : ''
	return (
		<div className={`menu-modal theme-or hidden-sm ${_className}`}>
			<nav id="secondary">
				<ul className="fXXL">
					{secondaire.map(({ menu_item, link_external, title }, i) => (
						<li key={i} className="offset-2 h25">
							<MenuItem
								node={menu_item}
								title={title}
								link_external={link_external}
							/>
						</li>
					))}
					{/* <li className="offset-2 h25">
						<div className="menu-item">
							<a
								href="https://victoirecycles.bigcartel.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								E-Boutique
							</a>
						</div>
					</li> */}
				</ul>
			</nav>
			<div className="footer h25">
				<div className="row middle-xs h100">
					<div className="col-md-2 hidden-xs text-right">
						{/* <Img {...settings.data.newsletter_image} /> */}
						{/* <pre>{JSON.stringify(settings.data.newsletter_image)}</pre> */}
						<GatsbyImage
							image={settings.data.newsletter_image.gatsbyImageData}
							alt="newsletter_image"
						/>
					</div>
					<div className="col-md-4 ">
						<NewsletterWrapper
							title={_getLocalizedText('newsletterTitle')}
							display="xdc"
							texte={settings.data.newsletter_message}
							hasToolTip={true}
						/>
					</div>
					<div className="col-md-3">
						<nav id="tertiaire">
							<ul className="fL">
								{tertiaire.map(({ menu_item, title }, i) => (
									<li key={i}>
										<MenuItem node={menu_item} title={title} />
									</li>
								))}
							</ul>
						</nav>
					</div>
					<div className="col-md-2">
						<nav id="quaternaire">
							<ul className="fL">
								{quaternaire.map(({ menu_item, title }, i) => (
									<li key={i}>
										<a
											rel="noopener noreferrer"
											target="_blank"
											href={menu_item.url}
										>
											{title}
										</a>
									</li>
								))}
							</ul>
						</nav>
					</div>
					<div className="col-md-1">
						<nav id="nav-switch-lang">
							<LangSwitcher locale={locale} />
						</nav>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MenuModal
