import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'gatsby'
import PubSub from 'pubsub-js'
import { _localizedSlug } from '../core/utils'

const MenuItem = ({ title, node, link_external }) => {
	const [current, setCurrent] = useState()
	// console.log(node)
	useEffect(() => {
		_handleCurrentMenuItem()

		const tokenRouteUpdate = PubSub.subscribe('ROUTE_UPDATE', (e, d) => {
			_handleCurrentMenuItem()
		})

		return () => PubSub.unsubscribe(tokenRouteUpdate)
	}, [])

	const _handleCurrentMenuItem = () => {
		const pathname = location.pathname
			? location.pathname
			: window.location.pathname
		// console.log(pathname.replace(/\//g, ""), node.uid )
		if (pathname.replace(/\//g, '') === node.uid) {
			setCurrent(true)
			PubSub.publish('CURRENT_MENU_ITEM', {
				uid: node.uid,
				title: title,
			})
		} else {
			setCurrent(false)
		}

		// console.log(pathname)
	}
	// console.log(link_external)
	const isExternal = useMemo(() => {
		return link_external && link_external?.url !== null
	}, [link_external])
	// console.log({ link_external, isExternal, title })

	return (
		<div className="menu-item">
			{isExternal && (
				<a
					href={link_external?.url}
					target={link_external?.target}
					rel="noopener noreferrer"
				>
					{title}
				</a>
			)}
			{!isExternal && (
				<Link
					to={`${_localizedSlug(node)}`}
					activeClassName="current-menu-item"
					className={current ? 'current-menu-item' : ''}
				>
					{title}
				</Link>
			)}
		</div>
	)
}

export default MenuItem
