import React, {useEffect} from 'react';
import PubSub from 'pubsub-js'
import { _getHexByName } from '../../core/utils'

const SectionCta = ({input}) => {
  // console.log(input)

  useEffect(() => {
    const AllBoutonContact = document.querySelectorAll(".section-cta .bouton-contact")
    if(AllBoutonContact){
      AllBoutonContact.forEach(el => {
        if(!el.classList.contains("has-evt")){
          el.addEventListener("click", _onClickContact)
          el.classList.add("has-evt")
        }
      })
    }
    return () => {
      if(AllBoutonContact){
        AllBoutonContact.forEach(el => {
          el.removeEventListener("click", _onClickContact)
        })
      }
    }
  }, [])

  const _onClickContact = (e) => {
    PubSub.publish("CONTACT", true)
  }

  const style = { 
    background: _getHexByName(input.background)
  }
  return (
    <section className={"section section-cta noma fXL"} style={style}>
      <div className="container-fluid">
				<div className="offset-2">
          <div
            className="texte "
            dangerouslySetInnerHTML={{
              __html: input.texte.html,
            }} ></div>
        </div>
      </div>
    </section>
  );
};

export default SectionCta;