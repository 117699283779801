import React from 'react'
import { useState } from 'react'
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {
	_getInDateRange,
	_getLocalizedText,
	_htmlSerializer,
} from '../core/utils'
import CtaBtn from './CtaBtn'
import Sections from './sections'
// import ExcerptToText from './ui/ExcerptToTexte'

const Event = ({ uid, input }) => {
	// console.log(input)

	/**
	 * states:
	 * is-inactive,
	 * is-excerpt,
	 * is-active
	 *
	 **/
	// const defaultState = index < 1 ? "is-excerpt" : "is-inactive"
	// const [eventState, setEventState] = useState(defaultState)

	const { du, au, title, chapo, cta_label, cta_url, texte, image, body } = input
	// console.log(input)
	const inRange = _getInDateRange(du, au)
	// console.log(inRange)
	const defaultState = inRange ? 'is-excerpt' : 'is-inactive'
	const [eventState, setEventState] = useState(defaultState)
	// console.log(body)
	// console.log("inRange", inRange)

	const _renderExcerptWithMore = texte => {
		// console.log(_htmlSerializer(texte))
		return (
			<p>
				{texte.text}
				{eventState !== 'is-active' && (
					<button className="more fSerIt" onClick={_toggleState}>
						<span>{_getLocalizedText('readMore')}</span>{' '}
						<span className="icon-chevron-e"></span>
					</button>
				)}
			</p>
		)
	}

	const _toggleState = e => {
		let newState = ''
		if (inRange) {
			if (eventState === 'is-inactive') newState = 'is-excerpt'
			if (eventState === 'is-excerpt') newState = 'is-active'
			if (eventState === 'is-active') newState = 'is-inactive'
		} else {
			newState = 'is-active'
		}

		setEventState(newState)
	}
	// const _toggleContent = (e) => {}
	return (
		<article className={`event ${eventState}`}>
			<div className="container-fluid">
				<hr />
			</div>
			<div className="event-header">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-2 hidden-xs"></div>
						<div className="col-sm-6 col-md-4 col-xs-12">
							<h2 id={uid} data-title={title.text} className="fL">
								{title.text}
							</h2>

							{(eventState === 'is-excerpt' || eventState === 'is-active') && (
								<div className="sm-only">
									{/* //<Img {...image} Tag="figure" /> */}
								</div>
							)}

							{eventState === 'is-inactive' && (
								<button
									className="more fRegIt fL has-icon chevron-e "
									onClick={_toggleState}
								>
									<span>{_getLocalizedText('readMore')}</span>
								</button>
							)}
							{(eventState === 'is-excerpt' || eventState === 'is-active') && (
								<div className="event-chapo">
									<div
										className="texte sans"
										dangerouslySetInnerHTML={{ __html: chapo.html }}
									></div>
									{cta_url && cta_url.url && (
										<CtaBtn label={cta_label} url={cta_url.url} />
									)}
								</div>
							)}
							{(eventState === 'is-excerpt' || eventState === 'is-active') && (
								<>
									<div
										className="texte"
										dangerouslySetInnerHTML={{ __html: texte.html }}
									></div>

									{eventState !== 'is-active' && (
										<button
											className="more fSerIt has-icon chevron-e"
											onClick={_toggleState}
										>
											<span>{_getLocalizedText('readMore')}</span>
										</button>
									)}
								</>
							)}
						</div>
						<div className="col-md-6 col-sm-6 col-xs-12">
							{(eventState === 'is-excerpt' || eventState === 'is-active') && (
								<div className="hidden-sm">
									{/* //<Img {...image} Tag="figure" /> */}
									<GatsbyImage
										image={image.gatsbyImageData}
										alt=""
										as="figure"
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			{eventState === 'is-active' && (
				<div className={`event-content`}>
					{body && <Sections body={body} />}
				</div>
			)}
		</article>
	)
}

export default Event
