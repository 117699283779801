import React from 'react';
import VeloIndex from './VeloIndex'

const PageHeader = ({index, h1}) => {
  return (
    <div className="page-header">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 col-xs-12 fXL">
            {index && <VeloIndex index={index} />}
          </div>
          <div className="col-xs">
            <h1>{h1}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;