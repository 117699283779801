import './src/styles/index.scss'

import {
	_wrapRootElement,
	_wrapPageElement,
	_onClientEntry,
	_onRouteUpdate,
	_onPreRouteUpdate,
} from './wrapPageElement'

export const wrapRootElement = _wrapRootElement
export const wrapPageElement = _wrapPageElement
export const onClientEntry = _onClientEntry
export const onRouteUpdate = _onRouteUpdate
export const onPreRouteUpdate = _onPreRouteUpdate
// exports.onInitialClientRender = () => {
//   console.log("ReactDOM.render has executed")
// }
