import React, { useContext } from "react"
// import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import NewsletterCard from "./NewsletterCard"

import { WrapperContext } from "./Layout"

const Rebonds = ({ input: { image, texte } }) => {
	// console.log(image)
	// console.log(rebond_text)
	const _WrapperContext = useContext(WrapperContext)
	const { settings, TOTAL_VELOS } = _WrapperContext
	const { newsletter_message, newsletter_image } = settings.data
	// console.log("TOTAL_VELOS", TOTAL_VELOS)

	const texteHtmlFormated = texte.html.replace("[%dernier%]", TOTAL_VELOS)
	// const rebond_link = texte.html.match(/href="([^"]*)/)[1] || ""
	// console.log(rebond_link)
	return (
		<section className="rebonds">
			<div className="container-fluid">
				<div className="row">
					<div className="col-s-6 col-xs-12">
						<NewsletterCard
							newsletter_image={newsletter_image}
							newsletter_message={newsletter_message}
						/>
					</div>
					<div className="col-s-6 col-xs-12">
						<article className="card card--rebond">
							{/* <Link to={rebond_link}> */}
							<div className="card-image">
								<div className="inner">
									{image && image.gatsbyImageData && (
										<>
											{/* <Img fluid={image.fluid} Tag="figure" /> */}
											<GatsbyImage image={image.gatsbyImageData} alt="" />
											<div className="overlay"></div>
										</>
									)}
								</div>
							</div>
							<div className="card-header fL">
								<div
									className="texte"
									dangerouslySetInnerHTML={{ __html: texteHtmlFormated }}
								></div>
							</div>
							{/* </Link> */}
						</article>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Rebonds
