import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import BgImg from 'gatsby-background-image'
import VeloIndex from './VeloIndex'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { _localizedSlug } from '../core/utils'

const HomeHero = ({ input }) => {
	const [heroImage, setHeroImage] = useState(input.image)
	const { image, image_mobile, velo } = input
	// console.log(input)

	useEffect(() => {
		if (image_mobile) {
			if (window.innerWidth <= 768) {
				setHeroImage(image_mobile)
			}
		}
	}, [])

	const url =
		typeof velo.document === 'object' ? _localizedSlug(velo.document) : '/'
	// console.log(url)
	return (
		<div className="home-hero fS">
			<Link to={url}>
				<div className="image-wrapper">
					{/* <BgImg
            {...heroImage}
            Tag="figure"
            style={{
              height: "100vh",
              // backgroundAttachment: "fixed"
            }}
          /> */}
					{/* <pre>{JSON.stringify(heroImage)}</pre> */}
					<GatsbyImage
						image={heroImage.gatsbyImageData}
						className="h-screen"
						alt=""
					/>
					{url}
					<div className="gradient"></div>
				</div>
			</Link>
		</div>
	)
}

export default HomeHero
