import React, { useState, useEffect } from "react"
// import Img from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Hero = ({ input, alt }) => {
	const [image, setImage] = useState(input)
	const [loadingClass, setLoadingClass] = useState("loading")

	const _onLoad = () => {
		setLoadingClass("")
	}

	useEffect(() => {
		if (alt) {
			if (window.innerWidth <= 768) {
				setImage(alt)
			}
		}
	}, [])

	return (
		<section className="section section-hero noma">
			<div className={`image ${loadingClass} h100`}>
				{/* <Img
          {...image}
          Tag="figure"
          style={{
            height: "100vh",
          }}
          onLoad={_onLoad}
        /> */}
				<GatsbyImage
					image={image.gatsbyImageData}
					className="h-screen- h100"
					alt=""
					onLoad={_onLoad}
				/>
			</div>
			<div className="gradient"></div>
		</section>
	)
}

export default Hero
