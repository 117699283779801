import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NewsletterWrapper from "./ui/NewsletterWrapper"
import { _getLocalizedText } from "../core/utils"

const NewsletterCard = ({ newsletter_image, newsletter_message }) => {
	// console.log(newsletter_image)
	return (
		<div className="card card--newsletter">
			<div className="card-image fL">
				<div className="inner text-center">
					<div>
						{/* <Img Tag="figure" {...newsletter_image} /> */}
						<NewsletterWrapper
							title={_getLocalizedText("newsletterTitle")}
							// display="inline"
							hasToolTip={false}
						/>
					</div>
				</div>
			</div>
			<div className="card-header fL">
				<div
					className="texte"
					dangerouslySetInnerHTML={{ __html: newsletter_message.html }}
				></div>
			</div>
		</div>
	)
}

export default NewsletterCard
