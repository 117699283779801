import React, { Component } from 'react';

class SummaryDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: true
        }
    }
    componentWillUnmount(){
        window.removeEventListener("resize", this._onResize)
    }
    componentDidMount(){
        this._onResize()
    }

    _onResize(){
        // const { collapsed } = this.state
        // if(collapsed)return;
        const { height } = this.refs.texte.getBoundingClientRect()
        // console.log(height)
        let summaryHeight = height+23
        // if('ontouchstart' in window)summaryHeight = height+70

        this.refs.summaryDetail.style.height = summaryHeight+"px"    
    }

    _onClick = () => {
        // alert("click")
        this.setState({
            collapsed: !this.state.collapsed
        }, () => {
            // this._onResize()
        })
    }

    render() {
        const { collapsed } = this.state
        const {summary, detail} = this.props
        const className = collapsed ? "collapsed" : ""
        return (
            <div className={"summary-detail "+className} ref="summaryDetail">
                <div className={`summary has-icon ${collapsed ? 'chevron-n' : 'chevron-s'}`} onClick={this._onClick}>
                    <span>{summary}</span> 
                    {/* <span className={`${collapsed ? 'icon-chevron-n' : 'icon-chevron-s'}`}></span> */}
                </div>
                <div className="details" ref="details">
                    <div className="texte" ref="texte"
                    dangerouslySetInnerHTML={{
                        __html: detail,
                    }}
                    ></div>
                </div>
            </div>
        );
    }
}

export default SummaryDetail;