import React, { useContext } from "react"
import SummaryDetail from "./ui/SummaryDetail"
import BackToTop from "./ui/BackToTop"

import { WrapperContext } from "./Layout"

import Logo from "../images/victoire-logo.inline.svg"

// const logo = require("../images/victoire-logo.svg")
// const laurier = require("../images/laurier.svg")
import Laurier from "../images//laurier.svg"

const Footer = () => {
	const _WrapperContext = useContext(WrapperContext)
	const { credits } = _WrapperContext.settings.data
	// console.log(credits)
	return (
		<footer>
			<div className="deco ">
				<div className="x xjc xac xdc">
					<img
						src={Laurier}
						alt="laurier"
						className="laurier"
						width="42px"
						height=""
					/>
					{/* <img src={logo} alt="victoire-logo" width="149px" height="" /> */}
					<Logo />
				</div>
			</div>
			<div className="x xjb xafe fS  ">
				<div className="col">
					<SummaryDetail summary="Crédits" detail={credits.html} />
					{/* <div className="copyright">© {new Date().getFullYear()} </div> */}
				</div>
				<div className="col">
					<BackToTop />
				</div>
			</div>
		</footer>
	)
}

export default Footer
