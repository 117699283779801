import React from 'react';

const VeloIndex = ({index}) => {
  return (
    <span className="index">
      N<span className="ordmasculine"></span><span className="num">{index}</span>
    </span>
  )
}

export default VeloIndex;