import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'
//import PubSub from 'pubsub-js';
import SEO from '../components/seo'
import Chapo from '../components/Chapo'
import VelosGrid from '../components/VelosGrid'
import Rebonds from '../components/Rebonds'
import Test from '../components/Test'

// import { FiltersContext } from '../components/Layout'
import { useContext } from 'react'

export const pageQuery = graphql`
	query Velos($locale: String!) {
		prismicPage(data: { template: { eq: "velos" } }, lang: { eq: $locale }) {
			_previewable
			data {
				title {
					text
				}
				texte {
					html
					text
				}
				body {
					... on PrismicPageDataBodyImagesTexte {
						slice_type
						primary {
							direction
							reverse
							texte {
								html
								text
							}
						}
						items {
							image {
								# ...sharp
								alt
								url
								gatsbyImageData(width: 2000)
							}
							# link {
							# 	url
							# 	document {
							# 		... on PrismicVelo {
							# 			data {
							# 				index
							# 			}
							# 		}
							# 	}
							# }
						}
					}
				}
				rebond_image {
					# ...sharp
					alt
					url
					gatsbyImageData(width: 2000)
				}
				rebond_texte {
					html
				}
			}
		}
		allPrismicVelo(
			sort: { fields: data___index, order: DESC }
			filter: { lang: { eq: $locale } }
		) {
			edges {
				node {
					uid
					lang
					type
					data {
						...veloCard
					}
				}
			}
		}
	}
`

const IS_BROWSER = typeof window !== 'undefined'

const PageVelo = ({ pageContext: { TOTAL_VELOS }, data }) => {
	// console.log(props)
	// const data = useMemo(() => {
	// 	// If we're not in a browser (i.e. we're in SSR) or preview data has not been
	// 	// set, use the non-preview static data.
	// 	if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

	// 	return mergePrismicPreviewData({
	// 		staticData,
	// 		previewData: window.__PRISMIC_PREVIEW_DATA__,
	// 	})
	// }, [staticData])

	// const limit = 10
	const { title, texte, body, rebond_image, rebond_texte } =
		data.prismicPage.data
	const { allPrismicVelo: velos } = data
	// const dernier = velos.edges.filter({node} => node.)
	//   const dernier = velos.edges[0].node.data.index
	// console.log(velos.edges[0].node.data)
	const texteHtmlFormated = texte.html.replace('[%dernier%]', TOTAL_VELOS)
	const texteTextFormated = texte.text.replace('[%dernier%]', TOTAL_VELOS)

	return (
		<>
			<SEO
				pageTitle={title.text}
				pageDescription={texteTextFormated}
				page={true}
				template="template-velos"
			/>

			<div className="page-content">
				<Chapo title={title.text} texte={texteHtmlFormated} />
				{velos && body && <VelosGrid input={velos} body={body} />}
			</div>

			{rebond_texte && (
				<Rebonds
					input={{
						image: rebond_image,
						texte: rebond_texte,
					}}
				/>
			)}
		</>
	)
}

export default PageVelo
