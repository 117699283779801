/* eslint react/prop-types: 0, react/display-name: 0 */
import React, { lazy } from 'react'
import Helmet from 'react-helmet'
import PubSub from 'pubsub-js'
import { Layout } from './src/components/Layout'

import {
	PrismicPreviewProvider,
	// componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import { _localizedSlug } from './src/core/utils'

import linkResolver from './src/core/linkResolver.js'

// import { withPrefix } from 'gatsby'
// const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>

// export default wrapPageElement

//https://github.com/prismicio/prismic-gatsby/blob/main/docs/06-preview-drafts.md
const repositoryConfigs = [
	{
		repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
		// linkResolver: linkResolver,
		linkResolver: doc => linkResolver(doc),
		componentResolver: {
			home: lazy(() => import('./src/templates/page-home.jsx')),
			pageBlog: lazy(() => import('./src/templates/page-blog.jsx')),
			pageBlogPost: lazy(() => import('./src/templates/page-blog-post.jsx')),
			pageBlogCategory: lazy(
				() => import('./src/templates/page-blog-category.jsx')
			),
			pageEvents: lazy(() => import('./src/templates/page-events.jsx')),
			pageVelos: lazy(() => import('./src/templates/page-velos.jsx')),
			// pageDefault: lazy(() => import('./src/templates/page-default.jsx')),
			PageDefault: lazy(() => import('./src/templates/page-default.jsx')),
			page: lazy(() => import('./src/templates/page-default.jsx')),
			// pageDefault: TemplatePageDefault,
			velo: lazy(() => import('./src/templates/page-velo.jsx')),
			veloCategory: lazy(
				() => import('./src/templates/page-velos-category.jsx')
			),
		},
	},
]
const _wrapRootElement = ({ element }) => {
	// return element;
	return (
		<>
			<Helmet>
				{/* <script>{`
          window.prismic = {
            'endpoint': 'https://victoire.cdn.prismic.io/api/v2'
          };
        `}</script>
				<script
					type="text/javascript"
					src="https://static.cdn.prismic.io/prismic.min.js?new=true"
				></script> */}
				{/* <script
					async
					defer
					src="https://static.cdn.prismic.io/prismic.js?new=true&repo=victoire"
				></script> */}
			</Helmet>
			<PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
				{element}
			</PrismicPreviewProvider>
		</>
	)
}

const _wrapPageElement = ({ element, props }) => {
	// console.log(props)
	return <Layout {...props}>{element}</Layout>
}

const _onClientEntry = () => {
	// console.log('_onClientEntry')
	// callAnalyticsAPI()
	document.body.classList.add('loading')
	const isTouch = 'ontouchstart' in window ? true : false

	if (isTouch) {
		document.documentElement.classList.add('touch')
	} else {
		document.documentElement.classList.add('no-touch')
	}

	window.onload = () => {
		// console.log('window.onload')

		setTimeout(() => {
			document.body.classList.remove('loading')
		}, 1000)
	}

	const delay = 4 * 60 * 1000
	// const delay = 1000
	// console.log('delay', delay)
	// const delay = 1000
	setTimeout(() => {
		PubSub.publish('SHOW_FORM')
	}, delay)
}

const _onPreRouteUpdate = ({ location, prevLocation }) => {
	// console.log(location, prevLocation)
	PubSub.publish('PRE_ROUTE_UPDATE', location)
}

const _onRouteUpdate = ({ location, prevLocation }) => {
	// console.log(location, prevLocation)
	PubSub.publish('ROUTE_UPDATE', location)
}

export {
	_wrapRootElement,
	_wrapPageElement,
	_onClientEntry,
	_onRouteUpdate,
	_onPreRouteUpdate,
}
