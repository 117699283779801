import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import VeloIndex from "../VeloIndex"

const Image = ({ input }) => {
	// const colClass = 12 / input.length
	// console.log(input)
	return (
		<section className="section section-image">
			<div className="container-fluid">
				<figure>
					{/* <Img {...input.image} /> */}
					<Figure image={input.image} link={input.link} />
					{input.texte && (
						<div className="offset-2">
							<figcaption
								className="texte "
								dangerouslySetInnerHTML={{
									__html: input.texte.html,
								}}
							></figcaption>
						</div>
					)}
				</figure>
			</div>
		</section>
	)
}

const Figure = ({ image, link }) => {
	const [loadingClass, setLoadingClass] = useState("loading")
	const _onLoad = () => {
		setLoadingClass("")
	}

	return (
		<figure
			className={`image ${loadingClass} ${
				image.fluid?.aspectRatio > 1 ? "landscape" : "portrait"
			}`}
		>
			{/* <pre>{JSON.stringify(image)}</pre> */}
			{/* <Img {...image} onLoad={_onLoad} /> */}
			<GatsbyImage image={image.gatsbyImageData} className="h-screen" alt="" />

			{link && link.document && link.document.data && (
				<Link className="fS x xac" to={link.url}>
					<span>Voir le</span> <VeloIndex index={link.document.data.index} />{" "}
					<span className="icon-chevron-e"></span>
				</Link>
			)}
		</figure>
	)
}

export default Image
