//const website = require('./website')

module.exports = {
    "fr-fr": {
      default: true,
      label: "Fr",
      path: "fr",
      locale: "fr-fr",
      siteLanguage: "fr",
      ogLang: "fr_FR",
      sending: "Veuillez patienter",
      success: "Merci !",
      error: "Une erreur interne inattendue s'est produite.",
      empty: "Vous devez écrire un e-mail.",
      duplicate: "Trop de tentatives d'abonnement pour cette adresse email",
      emailPlaceHolder: "adresse@email.com",
      yourMessage: "Message",
      send: "Envoyer",
      the: "le",
      by: "Par",
      close: "Fermer",
      menu: "Menu",
      filter: "Filtrer",
      credits: "Crédits",
      published: "Publié",
      updated: "Mis à jour",
      onTheBlog: "sur le blog",
      otherBikes: "D'autres vélos",
      inThisArticle: "Dans cet article",
      backBlog: "Retour à l’accueil du blog",
      categories: "Catégories",
      prevBlog: "Articles précédents",
      nextBlog: "Articles suivants",
      sections: "Sections",
      etapes: "Étapes",
      readMore: "En savoir plus",
      backTop: "Haut de page",
      allArticles: "Tous les articles",
      contact: "Contact",
      eraseAll: "Effacer tout",
      volontiers: "Volontiers",
      nonMerci: "Non, merci",
      backHome: "Retour à l'accueil",
      notFound: "Erreur 404",
      dans: "dans",
      delivered: "Livré",
      legal: "Légal",
      network: "Réseaux",
      theBikes: "Les vélos",
      validerSelection: "Valider la sélection",
      newsletterTitle: "Recevez des nouvelles de Victoire"
    },
    "en-gb": {
      default: false,
      label: "En",
      path: "en",
      locale: "en-gb",
      siteLanguage: "en",
      ogLang: "en_GB",
      sending: "Please wait",
      success: "Thank you!",
      error: "Une erreur interne inattendue s'est produite.",
      empty: "Vous devez écrire un e-mail.",
      duplicate: "Trop de tentatives d'abonnement pour cette adresse email",
      emailPlaceHolder: "address@email.com",
      yourMessage: "Message",
      send: "Send",
      the: "the",
      by: "By",
      close: "Close",
      menu: "Menu",
      filter: "Filter",
      credits: "Credits",
      published: "Pusblished",
      updated: "Updated",
      onTheBlog: "on the blog",
      otherBikes: "Other bikes",
      inThisArticle: "In this article",
      backBlog: "Back to blog index",
      categories: "Categories",
      prevBlog: "Previous posts",
      nextBlog: "Next posts",
      sections: "Sections",
      etapes: "Steps",
      readMore: "Read more",
      backTop: "Top of the page",
      allArticles: "All articles",
      contact: "Contact",
      eraseAll: "Delete all",
      volontiers: "Sure",
      nonMerci: "No, thanks",
      backHome: "Back home",
      notFound: "Error 404",
      dans: "in",
      delivered: "Delivered",
      legal: "Serious stuff",
      network: "Social",
      theBikes: "The bikes",
      validerSelection: "Validate selection",
      newsletterTitle: "Subscribe to our newsletter"
    }
  }
  