import React, { useContext, useState } from "react"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { _getLocalizedText } from "../core/utils"
import { WrapperContext } from "./Layout"
import Form from "./ui/Form"
import { useEffect } from "react"

const FormDelais = () => {
	const _WrapperContext = useContext(WrapperContext)
	const { settings } = _WrapperContext
	const {
		form_delais_title,
		form_delais_message,
		form_delais_merci,
		form_delais_image,
	} = settings.data

	const [step, setStep] = useState(1)
	const [className, setClassName] = useState("bottom")

	useEffect(() => {
		setTimeout(() => {
			setClassName("")
		}, 2000)
	}, [])

	const _onFormResponse = () => {
		setStep(3)
	}

	return (
		<div className={`form-delais theme-or ${className} step-${step}`}>
			<div className="header">
				{/* //<Img {...form_delais_image} Tag="figure" /> */}
			</div>

			<div
				className="icon-close fM"
				onClick={() => setClassName("bottom")}
			></div>

			<div className="content">
				<div className="step step--1">
					<div className="title">{form_delais_title.text}</div>
					<button className="more " onClick={() => setStep(2)}>
						{_getLocalizedText("volontiers")}
					</button>
					<span className="pipe">|</span>
					<button className="more  " onClick={() => setClassName("bottom")}>
						{_getLocalizedText("nonMerci")}
					</button>
				</div>
				<div className="step step--2">
					<Form
						action=""
						subject="demande de délais de fabrication"
						fields={[
							{
								name: "email",
								placeholder: _getLocalizedText("emailPlaceHolder"),
								type: "email",
								required: true,
							},
						]}
						messages={{
							sending: _getLocalizedText("sending"),
							success: _getLocalizedText("success"),
							error: _getLocalizedText("error"),
							empty: _getLocalizedText("empty"),
							duplicate: _getLocalizedText("duplicate"),
							button: _getLocalizedText("send"),
						}}
						className="form"
						// formClassName={display}
						callback={_onFormResponse}
					/>
					<div
						className="texte sans"
						dangerouslySetInnerHTML={{ __html: form_delais_message.html }}
					></div>
				</div>
				<div className="step step--3">
					<p>{_getLocalizedText("success")}</p>
					<div
						className="texte sans"
						dangerouslySetInnerHTML={{ __html: form_delais_merci.html }}
					></div>
				</div>
			</div>
		</div>
	)
}

export default FormDelais
