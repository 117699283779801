import React, { useMemo } from "react"
import { graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"
import SEO from "../components/seo"
import Chapo from "../components/Chapo"
import Article from "../components/Article"
import Rebonds from "../components/Rebonds"

export const pageQuery = graphql`
	query ArticleBySlug($uid: String!, $locale: String!) {
		prismicPage(data: { template: { eq: "blog" } }, lang: { eq: $locale }) {
			data {
				...page
			}
		}
		prismicArticle(uid: { eq: $uid }, lang: { eq: $locale }) {
			...article
		}
		# allPrismicArticle {
		#   edges {
		#     node {
		#       lang
		#       uid
		#     }
		#   }
		# }
	}
`

const IS_BROWSER = typeof window !== "undefined"

const BlogPost = ({ data }) => {
	// const data = useMemo(() => {
	//   if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

	//   return mergePrismicPreviewData({
	//     staticData,
	//     previewData: window.__PRISMIC_PREVIEW_DATA__,
	//   })
	// }, [staticData])

	// const lang = 'fr-fr'
	// const ArticlesInLang = data.allPrismicArticle.edges.filter(el => el.node.lang === lang)
	// console.log(data.allPrismicArticle.edges)
	// console.log(ArticlesInLang)
	// const { data } = props.data.prismicArticle
	// console.log(data)
	// console.log(data.prismicArticle.data)
	const blog = data.prismicPage.data
	const { title, texte } = data.prismicArticle.data
	const { rebond_image, rebond_texte } = blog

	return (
		<>
			<SEO
				pageTitle={title.text}
				pageDescription={texte.text}
				page={true}
				template={`template-blog-post theme-or`}
			/>

			<div className="page-content">
				<Chapo titraille={blog.title.text} texte={blog.texte.html} />
				<Article input={data.prismicArticle} single={true} />
			</div>
			{rebond_texte && (
				<Rebonds
					input={{
						image: rebond_image,
						texte: rebond_texte,
					}}
				/>
			)}
		</>
	)
}

export default BlogPost
