import React from 'react';
import { _getHexByName } from '../../core/utils'

const SectionTitreTexte = ({input}) => {

  const style = {
		background: _getHexByName(input.background)
	}
  return (
    <section className="section section-titre-texte" style={style}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-2 hidden-xs"></div>
					<div className="col-md-8 col-xs-12">
						<h2 
						className="fXL serif"
						
						>{input.titre.text}</h2>
						<div
							className="texte "
							dangerouslySetInnerHTML={{
								__html: input.texte.html,
							}} ></div>
					</div>
					<div className="col-md-2 hidden-xs"></div>
          
				</div>
				
			</div>
		</section>
  );
};

export default SectionTitreTexte;