import React, { useEffect } from "react"
import PubSub from 'pubsub-js'

const Texte = ({ input }) => {
  // console.log(input)
  useEffect(() => {
    const AllBoutonContact = document.querySelectorAll(".section-texte .bouton-contact")
    if(AllBoutonContact){
      AllBoutonContact.forEach(el => {
        if(!el.classList.contains("has-evt")){
          el.addEventListener("click", _onClickContact)
          el.classList.add("has-evt")
        }
      })
    }
    return () => {
      if(AllBoutonContact){
        AllBoutonContact.forEach(el => {
          el.removeEventListener("click", _onClickContact)
        })
      }
    }
  }, [])

  const _onClickContact = (e) => {
    PubSub.publish("CONTACT", true)
  }

	return (
		<section className="section section-texte">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-2 hidden-xs"></div>
					<div className="col-xs-12 col-md-8">
						<div
							className="texte "
							dangerouslySetInnerHTML={{
								__html: input.texte.html,
							}} ></div>
					</div>
          <div className="col-md-2 hidden-xs"></div>
				</div>
				
				{/* <div className="container">
					<div
						className="texte "
						dangerouslySetInnerHTML={{
							__html: input.texte.html,
						}} ></div>
				</div> */}
			</div>
		</section>
	)
}

export default Texte
