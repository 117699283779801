import React, { useState, useEffect, useRef } from 'react';
import PubSub from 'pubsub-js'
import { _getHexByName, _getInDateRange } from '../core/utils'

const Annonce = ({input}) => {
  const [inRange, setInRange] = useState()
  const annonceRef = useRef();
  // const now = new Date()
  // const du = new Date(input.du)
  // const au = new Date(input.au)
  // let inRange = now >= du && now <= au
  let isAnimating
  // console.log(input)
  useEffect(() => {
    const _inRange = _getInDateRange(input.du, input.au)
    setInRange(_inRange)
    if(!_inRange)return
    const token = PubSub.subscribe("ANNONCE_ANIME_OUT", (e, d) => {
      // PubSub.unsubscribe(token)
      // console.log(value,annonceRef)
      if(!isAnimating)_annoneAnimeOut(d)
    })
    
    return () => PubSub.unsubscribe(token)
  }, [])

  useEffect(() => {
    if(inRange){
      document.querySelector("header").classList.add("has-annonce")
    }else{
      document.querySelector("header").classList.remove("has-annonce")
    }
  }, [inRange])

  const _annoneAnimeOut = (out) => {
    if(out)
      annonceRef.current.classList.add("anime-out")
    else 
      annonceRef.current.classList.remove("anime-out")
  }

  const _onClick = () => {
    // console.log("_onClick")
    _annoneAnimeOut(true)
    setTimeout(() => {
      setInRange(false)
      // document.querySelector("header").classList.remove("has-annonce")
      PubSub.publish("ANNONCE_OUT")
    }, 600)
  }



  const style = {background: _getHexByName(input.background)}
// console.log(input)
  return !inRange
  ? null
  : (
    <div className="annonce" style={style} ref={annonceRef}>
      <div className="row ">
        <div className="col-md-2 hidden-sm"></div>
        <div className="col-xs">
          <div className="texte"
          dangerouslySetInnerHTML={{ __html: input.texte.html }}></div>
        </div>
        <div className="col-md-1 text-right">
          {/* <div className="icon-close fL" onClick={_onClick}></div> */}
        </div>
      </div>
      <div className="icon-close fL" onClick={() => _onClick()}></div>
    </div>
  );
};

export default Annonce;