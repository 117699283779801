import React from 'react';
import VeloCardSmall from './VeloCardSmall';
import { _getLocalizedText } from "../core/utils"

const RebondsVelo = ({input}) => {
// console.log(input)
  const data = input.filter(el => el.velo.document != null)
  // console.log(data)
  return !data.length
  ? null
  : (
    <section className="redonds-velo--article">
      <h3 className="fM">
        {_getLocalizedText("inThisArticle")}
      </h3>
      <div className="row">
      {data.map(({velo}, i) => (
        <div className="col-md-4 col-xs-12" key={i}>
          <VeloCardSmall input={velo.document} context="h4"  />
        </div>
      ))}
      </div>
    </section>
  );
};

export default RebondsVelo;