import React, { useContext } from 'react';
// import i18n from '../../../config/i18n'
import Mailchimp from './Mailchimp';
import { _getLocalizedText } from '../../core/utils'
import { WrapperContext } from "../Layout"

const NewsletterWrapper = ({title, display, texte, hasToolTip}) => {
  // const locale = "fr-fr"
  const _WrapperContext = useContext(WrapperContext)
  const { 
		locale
  } = _WrapperContext
  
  const url = locale === 'fr-fr' 
  ? 'https://victoire-cycles.us14.list-manage.com/subscribe/post?u=634063fedd1031149ebc97975&amp;id=9e99003d16'
  : 'https://victoire-cycles.us14.list-manage.com/subscribe/post?u=634063fedd1031149ebc97975&amp;id=9e99003d16'
  
  return (
    <div className="newsletter fL">
      <div className="title">{title}</div>

      <Mailchimp 
      action={url}
      fields={[
        {
          name: "EMAIL",
          placeholder: _getLocalizedText("emailPlaceHolder"),
          type: "email",
          required: true,
        },
      ]}
      messages={{
        sending: _getLocalizedText("sending"),
        success: _getLocalizedText("success"),
        error: _getLocalizedText("error"),
        empty: _getLocalizedText("empty"),
        duplicate: _getLocalizedText("duplicate"),
        button: _getLocalizedText("send"),
      }}
      className="mailchimp"
      formClassName={display} 
      hasToolTip={hasToolTip} 
      texte={texte} />
      
      {/* {texte && 
        <div
          className="texte fS"
          dangerouslySetInnerHTML={{ __html: texte.html }} ></div>
      } */}
    </div>
  );
};

export default NewsletterWrapper;

