import React from "react"
import VeloCardSmall from "../VeloCardSmall"

const Testimonials = ({ input }) => {

	// console.log(typeof input[0].velo.document )
	const _getTestimonial = (body) => {
		const testimonal = body.filter(el => el.slice_type === "testimonial")
		return testimonal[0] || null
	}

	
	// console.log((((input[0].velo || {}).document || {}).data || {}).body)
	// if(!input[0].velo.document?.data?.body)return null
	// if(!input[0].velo.document
	// 	&& !input[0].velo.document.data
	// 	&& !input[0].velo.document.data.body) return null

	const _input = input.filter(el => el.velo.document && el.velo.document.data)
// console.log(_input)
	return (
		<section className="section section-testimonials noma fL">
			<div className="container-fluid">
			{_input.map((item, i) => {
				
				// if(item.velo.document === undefined
				// 	&& item.velo.document.data === undefined)return null
				// console.log("item.velo.document.data",item.velo.document.data)
				const testimonal = _getTestimonial(item.velo.document.data.body)
				
				return(
					<div className="item" key={i}>
						<div className="row">
							{testimonal &&
								<>
								<div className="col-xs-12 col-sm-2 hidden-sm">
									<div className="fS person">{testimonal.primary.person}</div>
								</div>
								<div className="col-xs-12 col-sm-9 col-md-8">
									<blockquote
										className="quote"
										>{testimonal.primary.texte.text}</blockquote>
									<div className="sm-only ">
										<div className="fS person">{testimonal.primary.person}</div>
									</div>
								</div>
								</>
							}
							<div className="col-xs-12 col-sm-3 col-md-2">
								<VeloCardSmall input={item.velo.document} context="h2"/>
							</div>
						</div>
					</div>
				)
			})}
			</div>
			
		</section>
	)
}

export default Testimonials
