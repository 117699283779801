import React, { useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { WrapperContext } from "./Layout"
import PubSub from 'pubsub-js'
import Chapo from "../components/Chapo"
import Sections from "../components/sections"
import { _getLocalizedText } from '../core/utils';

const Contact = ({ active }) => {
  const [contactActive, setContactActive] = useState()
// console.log(active, contactActive)
  const _WrapperContext = useContext(WrapperContext)
  const { locale } = _WrapperContext

  const { contactFR, contactEN } = useStaticQuery(
    graphql`
    {
      contactFR: prismicPage(lang: {eq: "fr-fr"}, data: {template: {eq: "contact"}}) {
        data{
          ...contact
        }
      }
      contactEN: prismicPage(lang: {eq: "en-gb"}, data: {template: {eq: "contact"}}) {
        data{
          ...contact
        }
      }
    }
  `)

  const contact = locale === "fr-fr"
	? contactFR
  : contactEN
  
  useEffect(() => {
    // console.log("Contact : contactActive",contactActive)
    // PubSub.publish("CONTACT", contactActive)
  }, [contactActive])

  const _onCloseClick = () => {
    // setContactActive(false)
    PubSub.publish("CONTACT", false)
  }

  const {
    title,
    texte,
    body
  } = contact.data
  
  // const _toggle = () => setActive(!active)
  // console.log("active",active)
  return (
    <div className={`contact theme-or ${active ? 'is-active' : ''}`}>
      <div className="contact-content theme-or">
        <Chapo title={title.text} texte={texte.html} />
				{body && <Sections body={body} />}
      </div>
      <button className="btn-close fS sm-only" onClick={_onCloseClick}>
        {_getLocalizedText('close')}
      </button>
    </div>
  );
};

export default Contact;